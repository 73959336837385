import { useSelector, useDispatch } from 'react-redux'

import { HANDLE_LOADING, SET_APPLICATION } from 'store/ApplicationSlice'
import axiosInstance from 'utils/axios'
import axios from 'axios'
import { GET_API, POST_API, UPDATE_API } from 'utils/api'
import { useAlert } from 'hooks'

const useApplication = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()

  const { isLoading, application } = useSelector(state => state.application)

  const handleGetUserApplication = async () => {
    dispatch(HANDLE_LOADING(true))

    try {
      const res = await axiosInstance.get(GET_API().getCompanyApplication)
      if (res.data.status === 'success') {
        dispatch(SET_APPLICATION(res.data.application))
      }
      dispatch(HANDLE_LOADING(false))
    } catch (err) {
      console.log(err)
      dispatch(HANDLE_LOADING(false))
    }
  }

  const handleCreateApplication = async form => {
    dispatch(HANDLE_LOADING(true))
    const dataForm = new FormData()
    const formFields = Object.keys(form)
    formFields.forEach(field => {
      dataForm.append(field, form[field])
    })
    dataForm.append('files', form.personalIdentity?.file ?? form.personlIdentity)
    dataForm.append('files', form.coe?.file ?? form.coe)
    try {
      const res = await axiosInstance.post(POST_API().createCompanyApplication, dataForm, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (res.data.status === 'success') {
        dispatch(SET_APPLICATION(res.data.application))
        enqueueSnackbar('Submit successfully', { variant: 'success' })
      }
      dispatch(HANDLE_LOADING(false))
    } catch (err) {
      console.log(err)
      dispatch(HANDLE_LOADING(false))
      enqueueSnackbar('Submit fail', { variant: 'error' })
    }
  }

  const handleUpdateApplication = async (id, form) => {
    console.log(form)
    dispatch(HANDLE_LOADING(true))
    const dataForm = new FormData()
    const formFields = Object.keys(form)
    formFields.forEach(field => {
      dataForm.append(field, form[field])
    })
    dataForm.append('files', form.personalIdentity?.file ?? form.personlIdentity)
    dataForm.append('files', form.coe?.file ?? form.coe)
    try {
      const res = await axiosInstance.put(UPDATE_API(id).updateCompanyApplication, dataForm, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      console.log(res)
      if (res.data.status === 'success') {
        dispatch(SET_APPLICATION(res.data.companyApplication))
        enqueueSnackbar('Edit application successfully', { variant: 'success' })
      }
      dispatch(HANDLE_LOADING(false))
    } catch (err) {
      console.log(err)
      dispatch(HANDLE_LOADING(false))
      enqueueSnackbar('Submit fail', { variant: 'error' })
    }
  }

  const handleRemoveApplicationImages = name => {
    let newApplication
    if (name === 'personalIdentity') {
      newApplication = { ...application, personalIdentity: '' }
    } else {
      newApplication = { ...application, coe: '' }
    }
    dispatch(SET_APPLICATION(newApplication))
  }

  const handleUploadApplicationImages = (name, imgUrl) => {
    let newApplication
    if (name === 'personalIdentity') {
      newApplication = { ...application, personalIdentity: imgUrl }
    } else {
      newApplication = { ...application, coe: imgUrl }
    }
    dispatch(SET_APPLICATION(newApplication))
  }

  return {
    isLoading,
    application,
    handleGetUserApplication,
    handleCreateApplication,
    handleUpdateApplication,
    handleRemoveApplicationImages,
    handleUploadApplicationImages,
  }
}

export default useApplication
