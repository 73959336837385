import React from 'react'
import axios from 'utils/axios'

export default function useProject() {
  const [projects, setProjects] = React.useState([])
  const [project, setProject] = React.useState({})
  const [steps, setSteps] = React.useState([])
  const [taskDetail, setTaskDetail] = React.useState({})
  const [openTaskDetail, setOpenTaskDetail] = React.useState(false)
  const [openAddTask, setOpenAddTask] = React.useState(false)

  const handleToggleTask = () => {
    if (openTaskDetail) {
      setTaskDetail({})
    }
    setOpenTaskDetail(!openTaskDetail)
  }
  const handleToggleAddTask = () => {
    setOpenAddTask(!openAddTask)
  }

  const updateTask = (task, newStep) => {
    const newSteps = steps.map(step => {
      if (step?._id !== task?.stepId) return step
      return {
        ...newStep,
        tasks: step.tasks.map(t => {
          if (t?._id !== task?._id) return t
          return task
        }),
      }
    })
    setSteps(newSteps)
  }

  const handleGetProjects = async () => {
    try {
      const response = await axios.get('/projects')
      if (response.data.status === 'success') {
        setProjects(response.data.project)
        setProject(response.data.project[0])
      }
      console.log('handleGetProjects0', response)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCreateProject = async roomID => {
    try {
      const response = await axios.post(`/room/${roomID}/projects/create`)
      console.log('handleCreateProject', response)
    } catch (error) {
      console.log('err', error)
    }
  }

  async function handleLoopTasks(step) {
    try {
      const response = await axios.get(`/projects/${step.projectId}/steps/${step._id}/tasks`)
      if (!response.data.status === 'success') return
      return { ...step, tasks: response.data.tasks }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetTasks = async steps => {
    const promises = steps.map(step => handleLoopTasks(step))

    Promise.all(promises).then(results => {
      setSteps(results)
    })
  }

  const handleGetSteps = async projectID => {
    try {
      const response = await axios.get(`/projects/${projectID}/steps`)
      if (response.data.status === 'success') {
        handleGetTasks(response.data.steps)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleAssignedToTask = async (member, task, isDelete = false) => {
    const { stepId, projectId, _id, assignedTo } = task

    const newTaskDetail = {
      ...taskDetail,
      assignedTo: isDelete
        ? taskDetail.assignedTo.filter(mem => mem !== member?._id)
        : [member._id, ...taskDetail.assignedTo],
      assignedToImage: isDelete
        ? taskDetail.assignedToImage.filter((m, ind) => {
            if (taskDetail.assignedTo[ind] === member?._id) return
            return m
          })
        : [member.avatar, ...taskDetail.assignedToImage],
      members: {
        _id: isDelete
          ? [member?._id, ...taskDetail.members?._id]
          : taskDetail.members._id.filter(m => m !== member?._id),
        avatar: isDelete
          ? [member?.avatar, ...taskDetail.members?.avatar]
          : taskDetail.members.avatar.filter((m, ind) => {
              if (taskDetail.members._id[ind] === member?._id) return
              return m
            }),
      },
    }
    setTaskDetail(newTaskDetail)

    try {
      const newAssignedTo = isDelete ? assignedTo.filter(m => m !== member?._id) : [member?._id, ...assignedTo]
      const response = await axios.patch(`/projects/${projectId}/steps/${stepId}/tasks/${_id}/edit`, {
        assignedTo: newAssignedTo,
      })
      updateTask(response.data.task)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleTaskDetail = async task => {
    try {
      handleToggleTask()

      const response = await axios.get(`/task/${task?._id}/checklist`)
      const response1 = await axios.get(`/task/${task?._id}/taskfiles`)

      if (response.data.status === 'success') {
        setTaskDetail({
          ...task,
          checklist: response.data.checklist,
          taskFiles: response1.data.taskfiles,
          members: {
            _id: project.assignedTo.filter(x => !task.assignedTo.includes(x)),
            avatar: project.assignedToImage.filter(x => !task.assignedToImage.includes(x)),
          },
        })
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleEditTaskAndSubmit = async (form, task) => {
    const { projectId, stepId, _id } = task

    try {
      const response = await axios.patch(`/projects/${projectId}/steps/${stepId}/tasks/${_id}/edit`, { ...form })

      // update step
      const getStep = steps.filter(step => step?._id === task?.stepId)
      const newTask = getStep[0]

      if (form.status) {
        newTask.numFinishedTasks =
          form.status !== 'Complete' && task?.status === 'Complete'
            ? newTask.numFinishedTasks - 1
            : form.status === 'Complete'
            ? newTask.numFinishedTasks + 1
            : newTask.numFinishedTasks
      }
      if (form.deadline) {
        newTask.deadline = form.deadline
      }
      console.log('handleEditTaskAndSubmit newTask', response.data.task, newTask)
      setTaskDetail({ ...taskDetail, ...newTask })
      return updateTask(response.data.task, newTask)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleEditTask = async () => {
    const { stepId, projectId, _id } = taskDetail
    const { assignedTo, deadline, checklist } = taskDetail
    const form = { assignedTo, deadline, checklist }
    try {
      const response = await axios.patch(`/projects/${projectId}/steps/${stepId}/tasks/${_id}/edit`, form)
      updateTask(response.data.task)
      handleToggleTask()
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleChangeProject = ind => {
    setProject(projects[ind])
  }

  const handleCreateChecklist = async (content, task) => {
    try {
      const response = await axios.post(`/task/${taskDetail?._id}/checklist/create`, { content })
      const newTask = {
        ...taskDetail,
        checklist: [...taskDetail.checklist, response.data.checklist],
        numChecklist: taskDetail.numChecklist + 1,
      }
      setTaskDetail(newTask)

      const getStep = steps.filter(step => step?._id === task?.stepId)
      const newSteps = getStep[0]
      newSteps.numChecklist += 1
      return updateTask(newTask, newSteps)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleChangeChecklist = async (checklist, task) => {
    // update checklist task detail
    const newChecklist = taskDetail?.checklist.map(list => {
      if (list?._id !== checklist?._id) return list
      return { ...list, isChecked: !checklist.isChecked }
    })
    const newTask = {
      ...taskDetail,
      checklist: newChecklist,
      numFinishedChecklists: !checklist.isChecked
        ? taskDetail?.numFinishedChecklists + 1
        : taskDetail?.numFinishedChecklists - 1,
    }
    setTaskDetail(newTask)

    const getStep = steps.filter(step => step?._id === task?.stepId)
    const newSteps = getStep[0]
    newSteps.numFinishedChecklists = !checklist.isChecked
      ? taskDetail?.numFinishedChecklists + 1
      : taskDetail?.numFinishedChecklists - 1

    updateTask(newTask, newSteps)
    try {
      const response = await axios.patch(`/task/${checklist?.taskId}/checklist/${checklist?._id}/edit`, {
        isChecked: !checklist.isChecked,
      })
      console.log('handleChangeChecklist 2', checklist, response)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleRemoveChecklist = async (checklist, task) => {
    try {
      const response = await axios.delete(`/task/${checklist?.taskId}/checklist/${checklist?._id}/delete`)
      if (response.data.status === 'success') {
        const newChecklist = taskDetail.checklist.filter(check => check?._id !== checklist?._id)
        const newTask = {
          ...taskDetail,
          checklist: newChecklist,
          numChecklist: taskDetail?.numChecklist - 1,
          numFinishedChecklists: checklist?.isChecked
            ? taskDetail?.numFinishedChecklists - 1
            : taskDetail?.numFinishedChecklists,
        }
        setTaskDetail(newTask)

        const getStep = steps.filter(step => step?._id === task?.stepId)
        const newSteps = getStep[0]
        newSteps.numChecklist -= 1
        return updateTask(newTask, newSteps)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleAttachments = async (files, task) => {
    try {
      const formData = new FormData()
      await files.map(file => formData.append('files', file))

      const response = await axios.post(`/task/${task?._id}/taskfiles/upload`, formData)
      console.log('handleAttachments', response)

      if (response.data.status === 'success') {
        setTaskDetail({ ...taskDetail, taskFiles: [...response.data.taskfile, ...taskDetail.taskFiles] })
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleRemoveAttachments = async taskFile => {
    console.log('handleRemoveAttachments', taskFile)
    try {
      const response = await axios.delete(`/task/${taskFile?.taskId}/taskfiles/${taskFile?._id}/delete`)
      const newTaskFiles = taskDetail.taskFiles.filter(file => file?._id !== taskFile?._id)
      setTaskDetail({ ...taskDetail, taskFiles: newTaskFiles })
      console.log('handleRemoveAttachments', response)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCreateTask = async (form, step) => {
    try {
      const response = await axios.post(`/projects/${step?.projectId}/steps/${step?._id}/tasks/create`, form)
      if (response.data.status === 'success') {
        const newSteps = steps.map(s => {
          if (s?._id !== step?._id) return s
          return { ...s, tasks: [response.data.tasks, ...s.tasks] }
        })
        setSteps(newSteps)
        handleToggleAddTask()
      }
      console.log('handleCreateTask', response)
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleDeleteTask = async task => {
    try {
      const response = await axios.delete(
        `/projects/${task?.projectId}/steps/${task?.stepId}/tasks/${task?._id}/delete`
      )
      console.log('handleDeleteTask', response)

      if (response.data.status === 'success') {
        const newSteps = steps.map(step => {
          if (step?._id !== task?.stepId) return step
          return {
            ...step,
            numFinishedTasks: task?.status === 'Complete' ? step.numFinishedTasks - 1 : step.numFinishedTasks,
            tasks: step.tasks.filter(t => t?._id !== task?._id),
          }
        })
        setSteps(newSteps)
      }
    } catch (error) {
      console.log('err', error)
    }
  }
  return {
    projects,
    project,
    steps,
    taskDetail,
    openTaskDetail,
    openAddTask,
    handleTaskDetail,
    handleAssignedToTask,
    handleGetProjects,
    handleCreateProject,
    handleGetSteps,
    handleGetTasks,
    handleEditTask,
    handleChangeProject,
    handleToggleTask,
    handleEditTaskAndSubmit,
    handleCreateChecklist,
    handleChangeChecklist,
    handleRemoveChecklist,
    handleAttachments,
    handleRemoveAttachments,
    handleToggleAddTask,
    handleCreateTask,
    handleDeleteTask,
  }
}
