import React from 'react'
import { styled } from '@mui/styles'
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom'
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  Box,
  Button,
  Stack,
} from '@material-ui/core'
import AvatarActive from 'components/AvatarActive'
import SVGIconField from 'components/SVGIcon'
import useAuth from 'hooks/useAuth'
import { PATH_DASHBOARD } from 'routes/paths'
import LogoutIcon from '@mui/icons-material/Logout'
import { MButton } from 'components/_material-extend'
import { Label16, Label14, ColorStyle, Group } from './styles'
import CheckIcon from '@mui/icons-material/Check'

const SidebarContainer = styled(List)(({ theme }) => ({
  maxWidth: 260,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

const SidebarItem = styled(ListItem)(({ theme, active }) => ({
  padding: theme.spacing(0.4, 2),
  borderRight: active ? `2px solid ${theme.palette.primary.main}` : 'none',
  display: 'flex',
  alignItems: 'center',
}))

const SidebarTitle = styled(Typography)(({ theme, active }) => ({
  fontSize: 13,
}))

const ButtonStyle = styled(Button)(({ theme, active }) => ({
  fontSize: 14,
  fontWeight: active ? 'bolder' : 500,
  color: !active ? '#484861' : theme.palette.primary.main,
  '& path': {
    fill: active ? theme.palette.primary.main : '#8181A5',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

export default function SidebarList({ pathname, lists, ...props }) {
  const navigate = useNavigate()
  const { user, logout } = useAuth()

  const SidebarButton = styled(ListItemButton)(({ theme, active }) => ({
    // margin: theme.spacing(0, 1),
    backgroundColor: active ? theme.palette.primary.light : '#fff',
    color: active ? theme.palette.primary.main : '#000',
    borderRadius: theme.spacing(0.5),
    '& .MuiButton-startIcon': {
      width: 24,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& .NavLink': {
        color: theme.palette.primary.main,
        '& path': {
          fill: theme.palette.primary.main,
        },
      },
    },
  }))

  return (
    <SidebarContainer>
      <Box>
        {lists.map(sidebar => (
          <SidebarItem
            sx={{
              marginTop: sidebar.title !== 'Ticket Management' ? '4px' : '12px',
              marginBottom: sidebar.title !== 'Message' ? '4px' : '12px',
            }}
            key={sidebar.title}
            active={pathname === sidebar.path}
          >
            <SidebarButton
              sx={{
                '&::after': {
                  display: sidebar.title !== 'Ticket Management' ? 'none' : 'block',
                  content: '""',
                  width: '100%',
                  height: '1px',
                  position: 'absolute',
                  top: '-10px',
                  left: '0',
                  backgroundColor: '#c4c4c4',
                },
              }}
              data-tut={`reactour__${sidebar.path.split('/')[2]}`}
              active={pathname === sidebar.path}
              onClick={() => navigate(sidebar.path)}
            >
              <ButtonStyle
                className="NavLink"
                startIcon={sidebar.icon}
                disableRipple
                active={pathname === sidebar.path}
              >
                {/* <Typography></Typography> */}
                {sidebar?.title}
              </ButtonStyle>
            </SidebarButton>
          </SidebarItem>
        ))}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SidebarItem active={pathname === PATH_DASHBOARD.profile || undefined}>
          <SidebarButton
            active={pathname === PATH_DASHBOARD.profile || undefined}
            sx={{ justifyContent: 'space-between' }}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => navigate(PATH_DASHBOARD.profile)}
            >
              <AvatarActive src={user?.image_url} />
              <Stack direction="column">
                <SidebarTitle sx={{ ml: 1, fontWeight: 700, color: '#000' }}>
                  {user?.fullname}
                </SidebarTitle>

                {/* <MButton style={{ marginLeft: 5 }}>
                  <ColorStyle style={{ fontSize: 11 }}>Verify / Not Verify</ColorStyle>
                </MButton> */}
              </Stack>
            </Stack>
            <IconButton onClick={logout} sx={{ '&:hover': { '& path': { fill: '#ca3636' } } }}>
              <LogoutIcon />
            </IconButton>
          </SidebarButton>
        </SidebarItem>
      </Box>
    </SidebarContainer>
  )
}
