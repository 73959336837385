import React from 'react'
import { Dialog, DialogContent, Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Modal({ open, onClose, children, showOverflow, maxWidth, fullWidth }) {
  return (
    <Dialog
      open={open}
      transitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogContent
        sx={{
          overflow: !showOverflow && 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default Modal
