import { styled } from '@mui/styles'
import { Radio, RadioGroup, FormControlLabel, Box, Checkbox } from '@mui/material'
import { LabelStyle } from './search/FilterStyles'

const InputBoxStyle = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.875),
}))

const RadioStyle = ({ ariaLabel, value, handleChange, lists }) => {
  return (
    <RadioGroup
      row
      aria-label={ariaLabel}
      name="controlled-radio-buttons-group"
      value={value}
      onChange={handleChange}
    >
      {lists.map(list => (
        <FormControlLabel
          key={list.label}
          value={list.value}
          control={<Radio />}
          label={list.label}
          sx={{
            '& .MuiSvgIcon-root': {
              width: 18,
              height: 18,
            },
            '& .MuiTypography-root': {
              fontSize: 12,
              color: '#000',
            },
          }}
        />
      ))}
    </RadioGroup>
  )
}
const CheckboxField = ({ value, label, sx, ...props }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Checkbox />}
      label={label}
      labelPlacement="end"
      sx={{
        mt: 2,
        '&.MuiFormControlLabel-root': {
          marginRight: 0,
        },
        '& .MuiSvgIcon-root': {
          width: 20,
        },
        '& .MuiTypography-root': {
          fontSize: 12,
          fontWeight: 400,
          color: '#000',
        },
        ...sx,
      }}
      {...props}
    />
  )
}

const InputBoxField = ({ label, children, size, ...props }) => {
  return (
    <InputBoxStyle {...props}>
      <LabelStyle size={size}> {label}</LabelStyle>
      {children}
    </InputBoxStyle>
  )
}

export { RadioStyle, CheckboxField, InputBoxField }
