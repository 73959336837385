import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ColorStyle, Group, Label14, Label16 } from '../_dashboard/styles'
import { ReactComponent as EmptyStateCompany } from '../../assets/images/dashboard/EmptyState.svg'
import { MButton } from '../_material-extend'
import { PATH_DASHBOARD } from '../../routes/paths'

const Authorize = ({ handleAuthorize,tag }) => {
  const navigate = useNavigate()
  return (
    <>
      <Group>
        <EmptyStateCompany />
      </Group>
      <Group>
        <Label16
          weght="700"
          color="#8181A5"
          align="center"
          sx={{ display: 'block!important', mb: 2.5 }}
        >
          You have not registered the Company Authorization!
        </Label16>
        <Label14 color="#94959B" align="justify" sx={{ display: 'block!important', mb: 2.5 }}>
          To guarantee the security for highly confidential information on M&A Asia, all buyers are
          required to apply for Company Member Authorization.
        </Label14>
        <Label14 color="#94959B" align="justify" sx={{ display: 'block!important', mb: 2.5 }}>
          Once approved for the Authorization, users are able to use Deal Search, Contact Request
          and Negotiation tool with Seller.
        </Label14>
      </Group>

      <Group sx={{ mt: 3.4375 }}>
        {window.location.pathname === '/dashboard/profile' && tag !== 2 ? (
          <MButton onClick={() => handleAuthorize(2)}>
            <ColorStyle>Application for company authorization </ColorStyle>
          </MButton>
        ) : window.location.pathname === '/dashboard/search' ? (
          <MButton onClick={() => navigate(PATH_DASHBOARD.profile, { state: { tag: 2 } })}>
            <ColorStyle>Application for company authorization </ColorStyle>
          </MButton>
        ) : null}
      </Group>
    </>
  )
}

export default Authorize
