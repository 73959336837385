import React from 'react'
import axiosInstance from 'utils/axios'
import useAlert from './useAlert'
import { GET_API } from '../utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DEALS, HANDLE_LOADING, SET_PAGINATE } from '../store/DealRegisterSlice'

function useDealRegister() {
  const dispatch = useDispatch()
  const { isLoading, deals, paginate } = useSelector(state => state.dealRegister)

  const { enqueueSnackbar } = useAlert()

  const getUserDeals = async (page, type) => {
    dispatch(HANDLE_LOADING(true))
    try {
      const res = await axiosInstance.get(GET_API(0, page, type).userDeals)
      if (res.data.status === 'success') {
        dispatch(SET_DEALS(res.data.result))
        dispatch(SET_PAGINATE({ ...paginate, length: res.data.totalPage }))
        dispatch(HANDLE_LOADING(false))
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleCreateDealForBuyer = async form => {
    dispatch(HANDLE_LOADING(true))
    try {
      const response = await axiosInstance.post('/favoriteDeal', form)
      dispatch(HANDLE_LOADING(false))
      if (response.data.status === 'success') {
        dispatch(SET_DEALS([response.data.favoriteDeal, ...deals]))
        enqueueSnackbar('Create buyer deal successful', { variant: 'success' })
        return true
      }

      return false
    } catch (error) {
      dispatch(HANDLE_LOADING(false))
      enqueueSnackbar('Create buyer deal unsuccessful', { variant: 'error' })
      console.log('err', error)
    }
  }

  const handleCreateDealForSeller = async form => {
    dispatch(HANDLE_LOADING(true))
    try {
      const response = await axiosInstance.post('/sellerDeal', form)
      dispatch(HANDLE_LOADING(false))
      if (response.data.status === 'success') {
        dispatch(SET_DEALS([response.data.deal, ...deals]))
        enqueueSnackbar('Create seller deal successful', { variant: 'success' })
        return true
      }
      return false
    } catch (error) {
      dispatch(HANDLE_LOADING(false))
      enqueueSnackbar('Create seller deal unsuccessful', { variant: 'error' })
      console.log('err', error)
    }
  }

  const handlePaginate = paginate => {
    dispatch(SET_PAGINATE(paginate))
  }

  const resetPaginate = () => {
    dispatch(
      SET_PAGINATE({
        offset: 0,
        perPage: 5,
        currentPage: 0,
        length: 0,
      })
    )
  }

  return {
    isLoading,
    deals,
    paginate,
    getUserDeals,
    handleCreateDealForBuyer,
    handleCreateDealForSeller,
    handlePaginate,
    resetPaginate,
  }
}

export default useDealRegister
