import React from 'react'
import { Group, Label14, Label16, ColorStyle } from '../_dashboard/styles'
import UnderScreening from '../../assets/images/dashboard/UnderScreening.png'
import { styled } from '@mui/styles'
import { MButton } from 'components/_material-extend'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from '../../routes/paths'

const ImageFieldV2 = styled('img')(() => ({
  width: 140,
  height: 140,
  object: 'cover',
  margin: '0 auto',
}))

const Screening = ({ handleAuthorize, tag }) => {
  const navigate = useNavigate()
  return (
    <>
      <Group sx={{ mb: 2.5 }}>
        <ImageFieldV2 src={UnderScreening} alt="upload" />
      </Group>
      <Group sx={{ mb: 2.5 }}>
        <Label16
          weght="700"
          align="center"
          lineHeight=""
          sx={{ display: 'block!important', mb: 2.5 }}
        >
          Your application is under screening
        </Label16>
        <Label14 color="#94959B" align="left" sx={{ display: 'block!important', mb: 2.5 }}>
          We are reviewing your application. Once approved, we will noticed you via email.
        </Label14>
        <Label14 color="#94959B" align="left">
          After getting approval, users are able to use Deal Search, Contact Request and Negotiation
          tool with Seller.
        </Label14>
      </Group>
      <Group sx={{ mt: 3.4375 }}>
        {window.location.pathname === '/dashboard/profile' && tag !== 2 ? (
          <MButton onClick={() => handleAuthorize(2)}>
            <ColorStyle>Application for company authorization </ColorStyle>
          </MButton>
        ) : window.location.pathname === '/dashboard/search' ? (
          <MButton onClick={() => navigate(PATH_DASHBOARD.profile, { state: { tag: 2 } })}>
            <ColorStyle>Application for company authorization </ColorStyle>
          </MButton>
        ) : null}
      </Group>
    </>
  )
}

export default Screening
