import React from 'react'
import { styled } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { Typography, List, Box, Button } from '@material-ui/core'
import AvatarActive from 'components/AvatarActive'

import { useAuth } from 'hooks'
import { PATH_DASHBOARD } from 'routes/paths'
import LogoutIcon from '@mui/icons-material/Logout'

const SidebarContainer = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: 0,
  transition: 'all 1s ease-in',
}))

const ButtonStyle = styled(Button)(({ theme, active }) => ({
  fontSize: 14,
  fontWeight: 700,
  minWidth: 50,
  borderRadius: 0,
  color: !active ? '#000' : theme.palette.primary.main,
  padding: theme.spacing(2, 0),
  borderRight: !active ? 'none' : `2px solid ${theme.palette.primary.main}`,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& path': {
    fill: active ? theme.palette.primary.main : '#000',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

export default function SidebarList({ pathname, lists, ...props }) {
  const navigate = useNavigate()
  const { user, handleLogout } = useAuth()

  const SidebarItem = styled(Box)(({ theme, active }) => ({
    paddingBottom: 0,
    backgroundColor: !active ? '#fff' : theme.palette.primary.light,
    maxHeight: 50,
    marginBottom: 6,
    transition: 'all 500ms ease-in',
  }))
  return (
    <SidebarContainer>
      <Box>
        <SidebarItem>
          <ButtonStyle onClick={() => navigate(PATH_DASHBOARD.root)}>
            <Typography sx={{ color: 'primary.main', fontWeight: 700, fontSize: 20 }}>M</Typography>
          </ButtonStyle>
        </SidebarItem>
        {lists.map(sidebar => (
          <SidebarItem key={sidebar.title} active={pathname === sidebar.path || undefined} sx={{}}>
            <ButtonStyle
              title={sidebar.title}
              active={pathname === sidebar.path}
              startIcon={sidebar.icon}
              onClick={() => navigate(sidebar.path)}
            />
          </SidebarItem>
        ))}
        <SidebarItem active={pathname === PATH_DASHBOARD.profile || undefined}>
          <ButtonStyle
            active={pathname === PATH_DASHBOARD.profile || undefined}
            onClick={() => navigate(PATH_DASHBOARD.profile)}
          >
            <AvatarActive src={user?.image_url} sx={{ width: 18, height: 18 }} />
          </ButtonStyle>
        </SidebarItem>
      </Box>

      <Box>
        <ButtonStyle onClick={handleLogout} startIcon={<LogoutIcon />} />
      </Box>
    </SidebarContainer>
  )
}
