import { styled } from '@mui/styles'
import { Typography, Select, FormControl, Box } from '@mui/material'

const TitleStyle = styled('span')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
}))

const LabelStyle = styled(Typography)(({ theme, size }) => ({
  fontWeight: 400,
  fontSize: size ? `${size}` : 12,
  color: '#000',
}))

const Group = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
}))

const Split = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'absolute',
  bottom: -65,
  left: 0,
  width: '100%',
  // boxShadow: '0px -1px 3px rgba(211,211,211, 0.5)',
  background: '#fff',
  
  // borderBottom: '1px solid #d7d7e4',
  borderBottomRightRadius: 12,
  borderBottomLeftRadius: 12,
  border: '0.5px solid rgba(215, 215, 228)',
}))

const TitleType = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: '#212244',
  marginBottom: theme.spacing(2),
}))

export { TitleStyle, LabelStyle, Group, Split, TitleType }
