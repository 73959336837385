import React from 'react'
import io from 'socket.io-client'
import axiosInstance from 'utils/axios'
import { useAuth, useChat } from 'hooks'
// // const socket = io('171.236.226.14:4009')
const ENDPOINT = 'https://d9br4l58fpq2r.cloudfront.net'
// const ENDPOINT = 'http://localhost:8080'

let socket

export default function useSocket() {
  const { user } = useAuth()
  const { room } = useChat()

  const initiateSocket = roomId => {
    socket = io(ENDPOINT)
    console.log(`Connecting socket...`)
    if (socket && roomId) socket.emit('join', roomId)
  }

  const disconnectSocket = () => {
    console.log('Disconnecting socket...')
    if (socket) socket.disconnect()
  }

  const subscribeToChat = cb => {
    if (!socket) return true
    socket.on('SendMessage', msg => {
      console.log('Websocket event received!')
      return cb(null, msg)
    })
  }

  const subscribeToPin = cb => {
    if (!socket) return true
    socket.on('PingMessage', msg => {
      console.log('Websocket event received pin!', msg)
      return cb(null, msg)
    })
  }

  const subscribeToNotifications = cb => {
    if (!socket) return true
    socket.on('sendNotification', msg => {
      console.log('Websocket event sendNotification received!', msg)
      return cb(null, msg)
    })
  }
  const handleSendMessage = async values => {
    const { message, files } = values
    const formData = new FormData()

    if (files.length) {
      files.map(({ file }) => formData.append('files', file))
    }

    formData.append('sendById', user?.id)
    formData.append('sentBy', room?._id)
    formData.append('content', message)

    try {
      const response = await axiosInstance.post(`room/${room?._id}/message/create`, formData)
      console.log('response ', response)
    } catch (error) {
      console.log('err', error)
    }
  }

  return {
    initiateSocket,
    disconnectSocket,
    subscribeToChat,
    subscribeToNotifications,
    subscribeToPin,
    handleSendMessage,
  }
}
