// routes
import { PATH_DASHBOARD } from 'routes/paths'
// components
import SvgIconStyle from 'components/SVGIcon'
import { ReactComponent as SearchIcon } from 'assets/images/dashboard/IconsSidebar/search.svg'
import { ReactComponent as ManageIcon } from 'assets/images/dashboard/IconsSidebar/manage.svg'
import { ReactComponent as PenIcon } from 'assets/images/dashboard/IconsSidebar/pen.svg'
import { ReactComponent as ChatIcon } from 'assets/images/dashboard/IconsSidebar/chat.svg'
import { ReactComponent as ConfirmIcon } from 'assets/images/dashboard/IconsSidebar/confirm.svg'
import { ReactComponent as ManageticketIcon } from 'assets/images/dashboard/IconsSidebar/manageticket.svg'
import { ReactComponent as FaqIcon } from 'assets/images/dashboard/IconsSidebar/faq.svg'
import { ReactComponent as SupportIcon } from 'assets/images/dashboard/IconsSidebar/support.svg'

// ----------------------------------------------------------------------

const ICONS = {
  search: <SearchIcon />,
  manage: <ManageIcon />,
  register: <PenIcon />,
  chat: <ChatIcon />,
  confirm: <ConfirmIcon />,
  manageTicket: <ManageticketIcon />,
  faq: <FaqIcon />,
  support: <SupportIcon />,
}

const sidebarConfig = [
  {
    title: 'Search',
    path: PATH_DASHBOARD.search,
    icon: ICONS.search,
  },
  { title: 'Deal Register', path: PATH_DASHBOARD.dealRegister, icon: ICONS.register },
  { title: 'Message', path: PATH_DASHBOARD.chat, icon: ICONS.chat },
  { title: 'Deal management', path: PATH_DASHBOARD.dealManagement, icon: ICONS.manage },
  // {
  //   title: 'Information investigation',
  //   path: PATH_DASHBOARD.informationInvestigation,
  //   icon: ICONS.confirm,
  // },
  { title: 'Ticket Management', path: PATH_DASHBOARD.ticket, icon: ICONS.manageTicket },
  { title: 'FAQ', path: PATH_DASHBOARD.faq, icon: ICONS.faq },
  {
    title: 'Support',
    path: PATH_DASHBOARD.support,
    icon: ICONS.support,
  },
]

export default sidebarConfig
