// THIS IS THE DEFAULT VALUE YOU CAN CHANGE IF YOU WANT

const breakpoints = {
  // values: {
  //   xs: 0,
  //   sm: 600,
  //   md: 960,
  //   lg: 1280,
  //   xl: 1920,
  // },
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
  },
}

export default breakpoints
