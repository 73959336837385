import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  application: {
    _id: null,
    companyName: '',
    location: '',
    department: '',
    title: '',
    personalIdentity: null,
    coe: null,
    status: null,
  },
}

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    HANDLE_LOADING: (state, action) => {
      state.isLoading = action.payload
    },
    SET_APPLICATION: (state, action) => {
      state.application = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { HANDLE_LOADING, SET_APPLICATION } = actions

export default reducer
