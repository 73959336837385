import React from 'react'
import { styled, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link as RouterLink } from 'react-router-dom'

// components
import { ReactComponent as FBIcon } from 'assets/images/main/icons/fb.svg'
import { ReactComponent as TweetIcon } from 'assets/images/main/icons/tweeter.svg'
import { ReactComponent as LinkedinIcon } from 'assets/images/main/icons/linkedin.svg'
import { Stack, IconButton, Box, Typography, MenuList, MenuItem } from '@mui/material'

const data = [
  {
    label: 'About',
    links: [
      {
        link: '#',
        label: 'About',
      },
      {
        link: '#',
        label: 'Search For M&A Deals',
      },
      {
        link: '#',
        label: 'Register M&A Deals',
      },
      {
        link: '#',
        label: 'Proceed With M&A Negotiations',
      },
      {
        link: '#',
        label: 'Environment',
      },
      {
        link: '#',
        label: 'Professional Network',
      },
    ],
  },
  {
    label: 'About',
    links: [
      {
        link: '#',
        label: 'About',
      },
      {
        link: '#',
        label: 'Search For M&A Deals',
      },
      {
        link: '#',
        label: 'Register M&A Deals',
      },
      {
        link: '#',
        label: 'Proceed With M&A Negotiations',
      },
      {
        link: '#',
        label: 'Environment',
      },
      {
        link: '#',
        label: 'Professional Network',
      },
    ],
  },
  {
    label: 'Utility',
    links: [
      {
        link: '#',
        label: 'FAQ',
      },
      {
        link: '#',
        label: 'Terms & Conditions',
      },
    ],
  },
]
const RootStyle = styled(Box)(({ theme }) => ({
  background: '#fff',
  display: 'grid',
  padding: theme.spacing(0, 4),
  gridTemplateColumns: 'repeat(1,1fr)',
  rowGap: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    gridTemplateAreas: '"logo logo logo" "row0 row1 row2"',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 9),
    gridTemplateAreas: '"logo row0 row1 row2"',
  },
}))
const GridItem = styled(Box)(({ theme, name }) => ({
  [theme.breakpoints.up('sm')]: {
    gridArea: `${name}`,
  },
}))

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#EAEAEA',
  minWidth: 56,
  '&:not(:last-child)': {
    marginRight: theme.spacing(1),
  },
}))

const RouterLinkStyle = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  fontSize: 20,
  color: '#5F5F5F',
}))

export default function Header() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <RootStyle>
      <Box
        sx={{
          pt: 2.25,
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: {
            gridArea: 'logo',
            textAlign: 'left',
          },
        }}
      >
        <Typography sx={{ fontSize: 39, color: 'primary.main', fontWeight: 600 }}>M&A</Typography>
        <Typography sx={{ fontSize: 20, color: '#5F5F5F' }}>
          Transforming M&As to everyone‘s choice of strategies
        </Typography>
        <Stack
          direction="row"
          sx={{
            mt: 3,
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'center',
            },
          }}
        >
          <IconButtonStyle>
            <FBIcon />
          </IconButtonStyle>
          <IconButtonStyle>
            <TweetIcon />
          </IconButtonStyle>
          <IconButtonStyle>
            <LinkedinIcon />
          </IconButtonStyle>
        </Stack>
      </Box>
      {data.map(({ label, links }, index) => (
        <GridItem name={`row${index}`}>
          <Box
            sx={{
              [theme.breakpoints.up('md')]: {
                float: 'right',
                maxWidth: 225,
              },
            }}
          >
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ color: '#212244', fontSize: 24, fontWeight: 600 }}>{label}</Typography>
            </Box>
            <MenuList>
              {links.map(tag => (
                <MenuItem sx={{ py: 2, whiteSpace: 'pre-wrap' }}>
                  <RouterLinkStyle to={tag.link}>{tag.label}</RouterLinkStyle>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        </GridItem>
      ))}
    </RootStyle>
  )
}
