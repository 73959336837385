import React, { useState } from 'react'
import { styled } from '@mui/styles'
import { fToNow } from 'utils/formatTime'
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  Fade,
  Stack,
  Button,
  IconButton,
} from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { lineClampStyle } from './styles'
import InfiniteScroll from 'react-infinite-scroll-component'
// Notification Icon Image
import Warning from 'assets/images/dashboard/Warning.png'
import ApprovedIcon from 'assets/icons/Notifications/Approved Icon.svg'
import ContactRequestIcon from 'assets/icons/Notifications/Contact Request Icon.svg'
import MessageIcon from 'assets/icons/Notifications/Message Icon.svg'
import useNotifications from 'hooks/useNotifications'
import { useSocket, useAuth, useAlert } from 'hooks'
import LoadingNotifications from 'components/LoadingNotifications'

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgb(215, 215, 228)',
  padding: theme.spacing(1, 2),
  alignItems: 'center',
}))

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '70vh',
  overflowY: 'auto',
  flexDirection: 'column',
  padding: theme.spacing(1),
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#F5F5F5',
  },

  '&::-webkit-scrollbar': {
    width: 5,
    backgroundColor: 'red',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[500],
  },
}))

const ActiveStyle = styled(Button)(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  padding: 0,
  minWidth: 8,
  backgroundColor: theme.palette.primary.main,
  display: active && 'none',
}))

const ButtonItem = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'rgb(246, 246, 246)',
    '& .MuiTypography-root': {
      opacity: 1,
    },
  },
}))

const ImageField = styled('img')(() => ({
  width: 40,
  height: 40,
  object: 'cover',
  margin: '0 auto',
}))

const DialogStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: 'transparent',
  },
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiPaper-root': {
    maxWidth: 365,
    minWidth: 365,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />
})

export default function Notification(props) {
  const { isOpen, handleClose } = props

  const {
    handleGetNotification,
    notifications,
    isLoading,
    currentNotyPage,
    handleSetCurrentNotyPage,
    totalNotificationPages,
    totalUnSeenNotificationLength,
    handleUpdateUnSeenNotification,
    handleReadAllNotification,
    seenAll,
  } = useNotifications()
  const { initiateSocket, disconnectSocket, subscribeToNotifications } = useSocket()

  const { enqueueSnackbar } = useAlert()

  const { user } = useAuth()

  React.useEffect(() => {
    if (totalUnSeenNotificationLength > 0) {
      handleReadAllNotification()
    }

    handleGetNotification(1)
  }, [])

  React.useEffect(() => {
    if (user) initiateSocket(user?._id)
    subscribeToNotifications((err, data) => {
      if (err) return

      enqueueSnackbar(data.content, {
        variant: 'info',
        sx: {
          '& .SnackbarContent-root': {
            color: 'black',
            backgroundColor: 'grey.300',
          },
        },
        onClick: () => {
          console.log('Click')
        },
      })

      handleUpdateUnSeenNotification(data)
    })

    return () => {
      disconnectSocket()
    }
  }, [user, notifications])

  return (
    <DialogStyle
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Header>
        <Box>
          <Typography variant="h6">Notifications</Typography>
          {isLoading ? (
            <div />
          ) : (
            <Typography sx={{ fontSize: 12, fontWeight: 600, color: 'rgba(111 111 111 / 64%)' }}>
              You have <span>{totalUnSeenNotificationLength}</span> notifications
            </Typography>
          )}
        </Box>
        <IconButton
          sx={{ color: `${!seenAll ? '#ca3636' : 'gray'}` }}
          onClick={() => {
            handleReadAllNotification()
          }}
        >
          <DoneAllIcon />
        </IconButton>
      </Header>
      <DialogContent>
        <Content id="scrollableTarget">
          <InfiniteScroll
            style={{ overflow: 'hidden' }}
            dataLength={notifications?.length}
            next={() => {
              handleGetNotification(currentNotyPage + 1)
              handleSetCurrentNotyPage(currentNotyPage + 1)
            }}
            hasMore={currentNotyPage !== totalNotificationPages}
            scrollableTarget="scrollableTarget"
            loader={
              <div>
                <LoadingNotifications />
              </div>
            }
          >
            {notifications &&
              notifications.map(({ name, status, type, content, seen, createdAt }) => (
                <Stack direction="row" alignItems="center" key={name}>
                  <ButtonItem>
                    <ImageField
                      src={
                        status === 'error'
                          ? Warning
                          : status === 'success'
                          ? ApprovedIcon
                          : type === 'chat'
                          ? MessageIcon
                          : ContactRequestIcon
                      }
                      alt="Notification icon"
                    />
                    <Box sx={{ ml: 2, textAlign: 'left' }}>
                      <Typography
                        sx={{
                          opacity: `${(seen || seenAll) && 0.7}`,
                          fontSize: 14,
                          fontWeight: 600,
                          ...lineClampStyle(2),
                          color: 'rgba(95, 95, 95)',
                        }}
                      >
                        {name}{' '}
                        <Typography variant="span" sx={{ fontWeight: 500, color: 'black' }}>
                          {content.toLowerCase()}
                        </Typography>
                      </Typography>
                      <Typography variant="span" sx={{ fontSize: 10, color: 'rgba(0,0,0,0.5)' }}>
                        {fToNow(createdAt)}
                      </Typography>
                    </Box>

                    <ActiveStyle active={seen || seenAll} />
                  </ButtonItem>
                </Stack>
              ))}
          </InfiniteScroll>
        </Content>
      </DialogContent>
    </DialogStyle>
  )
}
