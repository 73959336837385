import React from 'react'

const imgExtension = ['png', 'jpeg', 'jpg']

export default function useFile({ files, path }) {
  const [fileLists, setFileLists] = React.useState([])
  const [imgLists, setImgLists] = React.useState([])

  React.useEffect(() => {
    if (!files) return
    const newFiles = path.map(file => {
      const getFileName = file.split('/')
      const id = getFileName[getFileName.length - 1].split('_')[0] // get id
      const name = getFileName[getFileName.length - 1].slice(id.length + 1) // get name
      const extArr = getFileName[getFileName.length - 1].split('.')
      const ext = extArr[extArr.length - 1] // get extension
      return { source: file, id, name, ext }
    })
    const fileListsNew = newFiles.filter(file => imgExtension.indexOf(file.ext) === -1)
    const imgListNew = newFiles.filter(file => imgExtension.indexOf(file.ext) !== -1)

    setFileLists(fileListsNew)
    setImgLists(imgListNew)
  }, [files])

  return { fileLists, imgLists }
}
