import React from 'react'
import { useLocation } from 'react-router-dom'

import { styled, useTheme } from '@mui/styles'
import { Box, Stack, IconButton } from '@mui/material'
import { PATH_DASHBOARD } from 'routes/paths'

// components
import LogoMain from 'components/LogoMain'
import { SidebarList, SidebarIcon } from 'components/_dashboard'

const RootStyle = styled(Box)(({ width }) => ({
  width: `${width}`,
  minWidth: `${width}`,
  height: '100vh',
  background: '#fff',
  position: 'fixed',
  left: 0,
  top: 0,
}))
const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'all 1s ease-in-out',
}))

const DashboardSidebar = React.forwardRef(({ lists, width, hideSidebar, ...props }, ref) => {
  const { pathname } = useLocation()
  const theme = useTheme()

  return (
    <RootStyle width={width} {...props} ref={ref}>
      {!hideSidebar ? (
        <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ m: theme.spacing(2, 4.375, 0) }}
          >
            <LogoMain redirect={PATH_DASHBOARD.root} />
          </Stack>

          <SidebarList pathname={pathname} lists={lists} hideSidebar={hideSidebar} />
        </Container>
      ) : (
        <Container>
          <SidebarIcon pathname={pathname} lists={lists} hideSidebar={hideSidebar} />
        </Container>
      )}
    
    </RootStyle>
  )
})

export default DashboardSidebar
