import React from 'react'
import { styled } from '@mui/styles'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { MButton } from 'components/_material-extend'

const MButtonStyle = styled(MButton)(({ theme }) => ({
  padding: theme.spacing(1, 4),
}))

export default function DialogTour(props) {
  const { handleClose, open, title, children, openTour } = props
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 9999999 }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MButtonStyle variant="contained" onClick={openTour}>
          Có, Cảm ơn
        </MButtonStyle>
        <MButtonStyle variant="gray" onClick={handleClose}>
          Không
        </MButtonStyle>
      </DialogActions>
    </Dialog>
  )
}
