import React from 'react'
import { useNavigate } from 'react-router-dom'
// hooks
import { useAuth } from 'hooks'
// routes
import { PATH_DASHBOARD } from 'routes/paths'

import LoadingScreen from 'components/LoadingScreen'

export default function GuestGuard({ children }) {
  const { isAuthenticated, isLoading } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!isAuthenticated) return
    return navigate(PATH_DASHBOARD.root)
  }, [isAuthenticated])

  if (isLoading) {
    return <LoadingScreen />
  }

  return <>{children}</>
}
