import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

// hooks
import useAuth from 'hooks/useAuth'
import { PATH_AUTH } from 'routes/paths'
// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const { isAuthenticated, isLoading } = useAuth()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null)

  React.useEffect(() => {
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname)
      }
      return <Navigate to={PATH_AUTH.login} />
    }

    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null)
      return <Navigate to={requestedLocation} />
    }
  }, [isAuthenticated])

  return <>{children}</>
}
