import React from 'react'
import { styled } from '@mui/styles'
import { Box, IconButton, Badge } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Notification from 'components/_dashboard/Notification'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import useSocket from 'hooks/useSocket'
import { useLocation, useNavigate } from 'react-router-dom'
import { Label18, ColorStyle, Group } from 'components/_dashboard/styles'
import { MButton } from 'components/_material-extend'
import { Typography } from '@material-ui/core'
import { useNotifications } from 'hooks'
import { PATH_DASHBOARD } from 'routes/paths'

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: '#212244',
  borderRadius: theme.spacing(0.8),
}))

const ImageField = styled('img')(() => ({
  width: 30,
  height: 30,
  object: 'cover',
  margin: '0 auto',
}))

const Container = styled(Box)(({ theme }) => ({
  marginTop: '5px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '5vh',
}))

export default function DashboardNavbar({ handleHideSidebar, isHide, SidebarConfig }) {
  const [openNotification, setOpenNotification] = React.useState(false)

  const {
    totalUnSeenNotificationLength,
    handleGetNotification,
    handleReadAllNotification,
    handleUpdateUnSeenNotification,
    notifications,
    seenAll,
  } = useNotifications()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleOpenNotification = () => {
     
    if (openNotification) {
      handleReadAllNotification()
      handleGetNotification(1)
    
    }
   setOpenNotification(!openNotification)
  }

  const page = SidebarConfig.find(item => item.path === pathname)

  React.useEffect(() => {
    handleGetNotification(1)
  }, [])

  
  return (
    <Container>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <IconButton onClick={handleHideSidebar}>
          {!isHide ? <MenuIcon /> : <DoubleArrowIcon />}
        </IconButton>

        <Label18 sx={{ marginLeft: 1 }} style={{ fontSize: 20 }}>
          {page?.title}
        </Label18>
      </Box>

      <Badge
        badgeContent={totalUnSeenNotificationLength}
        color="primary"
        sx={{
          '& .MuiBadge-badge': {
            top: 10,
            right: 10,
          },
        }}
      >
        <IconButtonStyle onClick={handleOpenNotification}>
          <NotificationsNoneIcon />
        </IconButtonStyle>
      </Badge>

      <Notification isOpen={openNotification} handleClose={handleOpenNotification} />
    </Container>
  )
}
