import { forwardRef } from 'react'
// material
import { styled } from '@mui/styles'
import { alpha, Button } from '@mui/material'

// ----------------------------------------------------------------------

const ButtonStyle = styled(Button)(({ theme, props }) => {
  const { color, variant, active } = props

  const styleContained = (color, active) => ({
    padding: theme.spacing(1, 2.175),

    fontSize: '1rem',
    fontWeight: 400,
    color: active ? theme.palette[color].contrastText : theme.palette[color].main,
    backgroundColor: active ? theme.palette[color].main : theme.palette[color].light,
    '&:hover': {
      backgroundColor: theme.palette[color].lighter,
      color: theme.palette[color].contrastText,
    },
  })

  const styleOutlined = color => ({
    color: theme.palette[color].main,
    border: `1px solid ${alpha(theme.palette[color].main, 0.48)}`,
    '&:hover': {
      border: `1px solid ${theme.palette[color].main}`,
      backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
    },
  })

  const styleText = color => ({
    color: theme.palette[color].main,
    borderRadius: 0,

    '&:hover': {
      backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
    },
  })

  const styleTextGrey = (color, active) => ({
    color: active ? '#000' : theme.palette.primary.main,
    border: '1px solid #EAEAEA',
    backgroundColor: color ? `${color}` : '#F6F6F6',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#fff',
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  })
  const styleSquare = color => ({
    backgroundColor: theme.palette[color].main,
    color: '#fff',
    border: 'none',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette[color].main,
    },
  })

  const styleGray = () => ({
    backgroundColor: '#F6F6F6',
    // color: '#B5B5D1',
    color: 'black',
    border: 'none',
  })

  const styleBorder = color => ({
    backgroundColor: theme.palette[color].main,
    color: '#fff',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette[color].main,
    },
  })

  return {
    ...(variant === 'contained' && { ...styleContained(color, active) }),
    ...(variant === 'outlined' && { ...styleOutlined(color, active) }),
    ...(variant === 'text' && { ...styleText(color, active) }),
    ...(variant === 'square' && { ...styleSquare(color, active) }),
    ...(variant === 'border' && { ...styleBorder(color, active) }),
    ...(variant === 'textGray' && { ...styleTextGrey(color, active) }),
    ...(variant === 'gray' && { ...styleGray() }),
  }
})

// ----------------------------------------------------------------------

const MButton = forwardRef(({ color = 'primary', variant = 'text', active = true, children, ...other }, ref) => {
  return (
    <ButtonStyle ref={ref} variant={variant} props={{ color, variant, active }} {...other}>
      {children}
    </ButtonStyle>
  )
})

// MButton.propTypes = {
//   children: PropTypes.node,
//   color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
//   variant: PropTypes.oneOfType([PropTypes.oneOf(['contained', 'outlined', 'text']), PropTypes.string]),
// }

export default MButton
