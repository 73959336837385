import React from 'react'
import { styled } from '@mui/styles'
import ReactPaginate from 'react-paginate'
import { Box } from '@material-ui/core'
import './PaginationStyle.css'

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default function Pagination({ handleChangePage, length, forcePage }) {
  return (
    <RootStyle>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={length}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handleChangePage}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        forcePage={forcePage}
      />
    </RootStyle>
  )
}
