import React from 'react'
import { styled } from '@mui/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import { MButton } from 'components/_material-extend'

const ButtonStyle = styled(MButton)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1.325, 0),
}))

const DialogStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root ': {
    maxWidth: 536,
  },
}))
export default function DialogUpgrade({ open, handleClose, handleUpgrade }) {
  return (
    <DialogStyle
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {'Please upgrade your account'}
      </DialogTitle>
      <DialogContent sx={{ px: 10, py: 2 }}>
        <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
          {'Upgrade your account to view more detailed information about this deal.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <ButtonStyle variant="gray" onClick={handleClose}>
          Cancel
        </ButtonStyle>
        <ButtonStyle variant="contained" onClick={handleUpgrade} autoFocus>
          Upgrade
        </ButtonStyle>
      </DialogActions>
    </DialogStyle>
  )
}
