import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'routes'
import ThemeConfig from 'theme'
import NotistackProvider from 'components/Notistack'

function App() {
  return (
    <ThemeConfig>
      <NotistackProvider>
        <Router>
          <Routes />
        </Router>
      </NotistackProvider>
    </ThemeConfig>
  )
}

export default App
