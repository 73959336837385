import React from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { ColorStyle, Group, Label14, Label16 } from '../_dashboard/styles'
import Rejected from '../../assets/images/dashboard/Rejected.png'
import { MButton } from '../_material-extend'
import { PATH_DASHBOARD } from '../../routes/paths'
import { styled } from '@mui/styles'

const ImageFieldV2 = styled('img')(() => ({
  width: 140,
  height: 140,
  object: 'cover',
  margin: '0 auto',
}))

const Reject = ({ handleAuthorize ,tag}) => {
  const navigate = useNavigate()

  return (
    <>
      <Group sx={{ mb: 2.5 }}>
        <ImageFieldV2 src={Rejected} alt="upload" />
      </Group>
      {window.location.pathname === '/dashboard/profile' && tag === 2 ? (
        <Group>
          <Label16
            weght="700"
            align="center"
            lineHeight=""
            sx={{ display: 'block!important', mb: 2.5 }}
          >
            We noticed there are some issues with your application
          </Label16>
          <Label14 color="#94959B" align="left" sx={{ display: 'block!important', mb: 2.5 }}>
            We have finished revising your application
          </Label14>
          <Label14 color="#94959B" align="center">
            Please make changes respect to the comments above and submit your documents again. Thank
            you
          </Label14>
        </Group>
      ) : (
        <Group>
          <Label16
            weght="700"
            align="center"
            lineHeight=""
            sx={{ display: 'block!important', mb: 2.5 }}
          >
            Your application has been rejected
          </Label16>
          <Label14 color="#94959B" align="left" sx={{ display: 'block!important', mb: 2.5 }}>
            We have finished revising your application
          </Label14>
          <Label14 color="#94959B" align="left">
            There are some changes that require you to make. Click the button below to review your
            application.
          </Label14>
        </Group>
      )}

      <Group sx={{ mt: 3.4375 }}>
        {/* weight="600" align="center" */}
        {window.location.pathname === '/dashboard/profile' && tag !== 2 ? (
          <MButton onClick={() => handleAuthorize(2)}>
            <ColorStyle>Application for company authorization </ColorStyle>
          </MButton>
        ) : window.location.pathname === '/dashboard/search' ? (
          <MButton onClick={() => navigate(PATH_DASHBOARD.profile, { state: { tag: 2 } })}>
            <ColorStyle>Find out why</ColorStyle>
          </MButton>
        ) : null}
      </Group>
    </>
  )
}

export default Reject
