import React from 'react'
import { styled, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// components
import AuthHeader from 'components/AuthHeader'
import NavMenuHeader from 'components/NavMenuHeader'
import LogoMain from 'components/LogoMain'
import MenuIcon from '@mui/icons-material/Menu'
import { Grid, IconButton } from '@mui/material'

const RootStyle = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: 120,
  padding: theme.spacing(4.5, 10.875, 3.125),
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4.5, 5.875, 3.125),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 4),
    height: 80,
  },
}))

const HamburgerMenu = styled(MenuIcon)(({ theme }) => ({
  color: '#000',
  fontWeight: 600,
  fontSize: theme.spacing(4.5),
}))

const LogoHeader = () => {
  return (
    <Grid item xs={4} md={3}>
      <LogoMain />
    </Grid>
  )
}

const MenuMobile = () => {
  return (
    <IconButton>
      <HamburgerMenu />
    </IconButton>
  )
}

export default function Header() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <RootStyle container direction="row" justifyContent="space-between" alignItems="center">
      <LogoHeader />
      {matches ? (
        <MenuMobile />
      ) : (
        <>
          <NavMenuHeader />
          <AuthHeader />
        </>
      )}
    </RootStyle>
  )
}
