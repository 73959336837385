import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from 'utils/axios'
import { GET_API, UPDATE_API } from 'utils/api'
import {
  SET_NOTIFICATIONS,
  HANDLE_LOADING,
  HANDLE_UPDATE_UNSEEN,
  HANDLE_READ_ALL_NOTIFICATIONS,
  HANDLE_SET_CURRENT_PAGE,
  SET_NOTIFICATIONS_LENGTH,
  HANDLE_SHOW_TOAST_NOTIFICATION,
  HANDLE_CLOSE_TOAST,
} from '../store/NotiSlice'

function useNotifications(page) {
  const dispatch = useDispatch()
  const {
    notifications,
    totalNotificationLengths,
    totalNotificationPages,
    isLoading,
    totalUnSeenNotificationLength,
    hasMore,
    currentNotyPage,
    seenAll,
    isShowToast,
  } = useSelector(state => state.noti)

  const handleGetNotification = async page => {
    console.log(page)
    try {
      const API_URL = GET_API(0, page).getNotifications
      dispatch(HANDLE_LOADING(true))
      const response = await axiosInstance.get(API_URL)
      if (response.data.status === 'success') {
        if (page === 1) {
          dispatch(SET_NOTIFICATIONS(response.data.notifications))
          dispatch(SET_NOTIFICATIONS_LENGTH(response.data))
        } else {
          const newArrNoti = [...notifications, ...response.data.notifications]
          dispatch(SET_NOTIFICATIONS(newArrNoti))
        }
        dispatch(HANDLE_LOADING(false))
      } else {
        dispatch(HANDLE_LOADING(false))
      }
    } catch (error) {
      console.log('error', error)
      dispatch(HANDLE_LOADING(false))
    }
  }

  const handleUpdateUnSeenNotification = async newNoty => {
    try {
      const newArrNoti = [newNoty, ...notifications]
      dispatch(SET_NOTIFICATIONS(newArrNoti))

      dispatch(HANDLE_UPDATE_UNSEEN())
    } catch (error) {
      console.log('error', error)
      dispatch(HANDLE_LOADING(false))
    }
  }

  const handleReadAllNotification = async () => {
    try {
      // update total = 0
      // call api
      const API_URL = UPDATE_API().updateStatusNotification
      const response = await axiosInstance.put(API_URL)

      dispatch(HANDLE_READ_ALL_NOTIFICATIONS())
    } catch (error) {
      console.log('error', error)
      dispatch(HANDLE_LOADING(false))
    }
  }

  const handleShowToastNotification = async () => {
    try {
      dispatch(HANDLE_SHOW_TOAST_NOTIFICATION(true))
    } catch (error) {
      console.log('error', error)
      dispatch(HANDLE_LOADING(false))
    }
  }

  const handleCloseToast = async () => {
    try {
      dispatch(HANDLE_CLOSE_TOAST(true))
    } catch (error) {
      console.log('error', error)
      dispatch(HANDLE_LOADING(false))
    }
  }

  const handleSetCurrentNotyPage = page => {
    dispatch(HANDLE_SET_CURRENT_PAGE(page))
  }

  return {
    handleGetNotification,
    handleSetCurrentNotyPage,
    isLoading,
    currentNotyPage,
    notifications,
    totalNotificationLengths,
    totalNotificationPages,
    totalUnSeenNotificationLength,
    handleUpdateUnSeenNotification,
    handleReadAllNotification,
    hasMore,
    seenAll,
    handleShowToastNotification,
    isShowToast,
  }
}

export default useNotifications
