import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
// contexts
// import { AuthProvider } from 'contexts/JWTContext'
import App from './App'
import { TourProvider } from '@reactour/tour'
import { searchSteps, dealRegisterSteps } from 'utils/steps/'

import { Provider } from 'react-redux'
import store from 'store'

ReactDOM.render(
  <Provider store={store}>
    <TourProvider steps={searchSteps}>
      <App />
    </TourProvider>
  </Provider>,
  document.getElementById('root')
)
