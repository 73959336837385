import React, { useState } from 'react'
import { styled } from '@mui/styles'
import { MButton } from 'components/_material-extend'
import * as Yup from 'yup'
import { useFormik, FormikProvider, Form } from 'formik'
import { useFilter, useApplication } from '../../../hooks'
import { TextField, Box, InputAdornment } from '@mui/material'
import { Split, TitleType } from './FilterStyles'
import { SelectField, GridContainer, GridItem, Label12, MOutlinedInput, TitleStyle } from '../styles'
import { ContainerStyle, GridContainerField } from './styles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useDispatch } from 'react-redux'
import { RadioStyle, CheckboxField, InputBoxField } from '../FormField'
import { ColorStyle } from 'components/_dashboard/search/ResultStyles'
import businessTypes from 'assets/data/businessTypes'
import countryData from 'assets/data/countries'
import industries from 'assets/data/industry'
import numEmployees from 'assets/data/numEmployees'
import { Authorize, Screening, Reject } from '../../authorization'
import { SET_FILTER_SEARCH, SET_TYPE } from '../../../store/FilterSlice'

const RootStyle = styled(Box)(({ theme }) => ({
  // overflow: 'hidden',
  borderRadius: 12,
}))

const ButtonStyle = styled(MButton)(({ theme, sx }) => ({
  fontSize: 12,
  fontWeight: '700',
  padding: theme.spacing(1.25),
  ...sx,
}))

const ScrollStyle = styled(Box)(({ theme }) => ({
  border: '0.5px solid rgb(215, 215, 228)',
  borderRadius: theme.spacing(1.25),
  borderBottom: 'none',
}))

const GroupStyle = styled(Box)(({ theme, last }) => ({
  borderBottom: last ? 'none' : `0.5px solid #D7D7E4`,
  padding: theme.spacing(1.5, 1.5, 1.25, 1.5),
}))

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: 12,
  // minHeight: 400,
  marginTop: theme.spacing(1),
  height: 'auto',
  // '@media (max-width: 1550px)': {
  //   height: '85vh',
  // },
  // '@media (min-width: 1550px)': {
  //   height: '84vh',
  // },
  // '@media (min-width: 2700px)': {
  //   height: '87vh',
  // },
}))

const BoxControl = styled(Box)(({ theme, nomb }) => ({
  marginBottom: !nomb && theme.spacing(1),
}))

const StatusContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 3),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  borderBottom: '0.5px #D7D7E4 solid',
}))

const initialize = {
  typeOfEnterprise: 'Business Type',
  location: 'Target Business Location',
  industry: 'Industry',
  askingPrice: {
    min: '',
    max: '',
  },
  ebitda: {
    min: '',
    max: '',
  },
  annualRevenue: {
    min: '',
    max: '',
  },
  numEmployee: 'Number Of Employees',
}

const sellerOrBuyerSelect = [
  {
    label: 'Seller Deals',
    value: 'seller',
  },
  {
    label: 'Buyer Needs',
    value: 'buyer',
  },
]

export default function Filter({ isBuyer, handleChangePosition }) {
  const dispatch = useDispatch()
  const { handleGetDeals, getFilteredDeals, paginate, isFilteredSearch, type, enableFilterSearch } = useFilter()
  const { application } = useApplication()
  const [filterBody, setFilterBody] = useState({})
  const [key, setKey] = React.useState('All')

  const formik = useFormik({
    initialValues: initialize,
    onSubmit: async values => {
      let minEmployee = ''
      let maxEmployee = ''
      if (values.numEmployee !== 'Number Of Employees') {
        if (values.numEmployee.includes('>')) {
          // eslint-disable-next-line prefer-destructuring
          minEmployee = values.numEmployees.split('> ')[1]
        } else {
          const minMaxArr = values.numEmployee.split(' - ')
          // eslint-disable-next-line prefer-destructuring
          minEmployee = minMaxArr[0]
          // eslint-disable-next-line prefer-destructuring
          maxEmployee = minMaxArr[1]
        }
      }

      const numEmployeesObj = {
        min: minEmployee,
        max: maxEmployee,
      }

      const body = {
        ...values,
        industry: values.industry !== 'Industry' ? values.industry : '',
        location: values.location !== 'Target Business Location' ? values.location : '',
        typeOfEnterprise: values.typeOfEnterprise !== 'Business Type' ? values.typeOfEnterprise : '',
        numEmployee: numEmployeesObj,
      }
      setFilterBody(body)
      await getFilteredDeals(1, body)
      enableFilterSearch(true)
    },

    onReset: async () => {
      await handleGetDeals()
    },
  })

  const { values, handleChange, handleSubmit, handleReset } = formik

  React.useEffect(() => {
    if (isFilteredSearch) {
      getFilteredDeals(paginate.currentPage + 1, filterBody)
    } else {
      console.log('use effect filter', type)
      handleGetDeals(paginate.currentPage + 1, isBuyer === 'buyer' ? 'buyerNeeds' : 'sellerDeals')
    }
  }, [paginate.currentPage])

  return (
    <RootStyle>
      <TitleStyle>Filter</TitleStyle>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Container sx={{ minHeight: key !== 'All' ? 400 : 650, borderRadius: 12 }} data-tut="reactour__filter">
            <ScrollStyle>
              <GroupStyle>
                <RadioStyle
                  ariaLabel="sellerOrBuyer"
                  lists={sellerOrBuyerSelect}
                  value={isBuyer}
                  handleChange={handleChangePosition}
                />
              </GroupStyle>

              {application?.status !== 'Approved' && isBuyer === 'seller' ? (
                <StatusContainer>
                  {application?.status === 'Under Screening' ? (
                    <Screening />
                  ) : application?.status === 'Rejected' ? (
                    <Reject />
                  ) : (
                    <Authorize />
                  )}
                </StatusContainer>
              ) : (
                <>
                  <GroupStyle>
                    <BoxControl>
                      <SelectField
                        label="Business Type"
                        name="typeOfEnterprise"
                        lists={businessTypes}
                        value={values.typeOfEnterprise}
                        defaultValue="Business Type"
                        handleChange={handleChange}
                        // handleChange={e => handleChange(e, 'type')}
                      />
                    </BoxControl>
                    <BoxControl>
                      <SelectField
                        label="Location"
                        name="location"
                        value={values.location}
                        defaultValue="Target Business Location"
                        lists={countryData}
                        handleChange={handleChange}
                        // handleChange={e => handleChange(e, 'location')}
                      />
                    </BoxControl>
                    <BoxControl>
                      <SelectField
                        label="Industry"
                        name="industry"
                        lists={industries}
                        value={values.industry}
                        defaultValue="Industry"
                        handleChange={handleChange}
                        // handleChange={e => handleChange(e, 'industry')}
                      />
                    </BoxControl>
                  </GroupStyle>
                  {key === 'All' ? (
                    <>
                      <GroupStyle last="true">
                        <Label12 color="#8181A5" sx={{ mb: 1 }} fontWeight={600}>
                          Asking Price (Million USD)
                        </Label12>
                        <GridContainerField sx={{ flexWrap: 'nowrap' }}>
                          <GridItem>
                            <MOutlinedInput
                              style={{ backgroundColor: '#F5F5FA' }}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              name="askingPrice.min"
                              variant="outlined"
                              value={values.askingPrice.min}
                              onChange={handleChange}
                              placeholder="Min"
                              required
                              type="number"
                            />
                          </GridItem>
                          <ArrowForwardIcon
                            sx={{
                              width: '40px',
                              height: '15px',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-30%, -50%)',
                            }}
                          />
                          <GridItem>
                            <MOutlinedInput
                              style={{ backgroundColor: '#F5F5FA' }}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              name="askingPrice.max"
                              variant="outlined"
                              value={values.askingPrice.max}
                              onChange={handleChange}
                              placeholder="Max"
                              required
                              type="number"
                            />
                          </GridItem>
                        </GridContainerField>
                      </GroupStyle>

                      <GroupStyle last="true">
                        <Label12 color="#8181A5" sx={{ mb: 1 }} fontWeight={600}>
                          Target EBITDA (Million USD)
                        </Label12>
                        <GridContainerField sx={{ flexWrap: 'nowrap' }}>
                          <GridItem>
                            <MOutlinedInput
                              style={{ backgroundColor: '#F5F5FA' }}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              name="ebitda.min"
                              variant="outlined"
                              value={values.ebitda.min}
                              onChange={handleChange}
                              placeholder="Min"
                              required
                              type="number"
                            />
                          </GridItem>
                          <ArrowForwardIcon
                            sx={{
                              width: '40px',
                              height: '15px',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-30%, -50%)',
                            }}
                          />
                          <GridItem>
                            <MOutlinedInput
                              style={{ backgroundColor: '#F5F5FA' }}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              name="ebitda.max"
                              variant="outlined"
                              value={values.ebitda.max}
                              onChange={handleChange}
                              placeholder="Max"
                              required
                              type="number"
                            />
                          </GridItem>
                        </GridContainerField>
                      </GroupStyle>

                      <GroupStyle last="true">
                        <Label12 color="#8181A5" sx={{ mb: 1 }} fontWeight={600}>
                          Latest Revenue (Million USD)
                        </Label12>
                        <GridContainerField sx={{ flexWrap: 'nowrap' }}>
                          <GridItem>
                            <MOutlinedInput
                              style={{ backgroundColor: '#F5F5FA' }}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              name="annualRevenue.min"
                              variant="outlined"
                              value={values.annualRevenue.min}
                              onChange={handleChange}
                              placeholder="Min"
                              required
                              type="number"
                            />
                          </GridItem>
                          <ArrowForwardIcon
                            sx={{
                              width: '40px',
                              height: '15px',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-30%, -50%)',
                            }}
                          />
                          <GridItem>
                            <MOutlinedInput
                              style={{ backgroundColor: '#F5F5FA' }}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              name="annualRevenue.max"
                              variant="outlined"
                              value={values.annualRevenue.max}
                              onChange={handleChange}
                              placeholder="Max"
                              required
                              type="number"
                            />
                          </GridItem>
                        </GridContainerField>

                        <BoxControl sx={{ marginTop: 3 }}>
                          <SelectField
                            name="numEmployee"
                            label="Number Of Employees"
                            lists={numEmployees}
                            value={values.numEmployee}
                            defaultValue="Number Of Employees"
                            handleChange={handleChange}
                            // handleChange={e => handleChange(e, 'numEmployee')}
                          />
                        </BoxControl>
                      </GroupStyle>
                    </>
                  ) : null}
                </>
              )}
            </ScrollStyle>

            {(application?.status === 'Approved' || isBuyer === 'buyer') && (
              <Split>
                <ButtonStyle type="submit" fullWidth variant="contained">
                  Search
                </ButtonStyle>
                <ButtonStyle
                  onClick={handleReset}
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: 10,
                    backgroundColor: 'gray',
                    '&:hover': {
                      backgroundColor: 'gray',
                    },
                  }}
                >
                  Reset
                </ButtonStyle>
              </Split>
            )}
          </Container>
        </Form>
      </FormikProvider>
    </RootStyle>
  )
}
