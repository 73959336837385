import React from 'react'
import '../assets/css/LoadingNotifications.css'

export default function LoadingNotifications() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner" />
    </div>
  )
}
