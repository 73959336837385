import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { GridContainer } from '../styles'

const ContainerStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 1),
  padding: theme.spacing(0, 2),
  '&:not(:last-child)': {
    borderBottom: '1px solid #ececf2',
    paddingBottom: theme.spacing(2),
  },
}))

const GroupStyle = styled(Box)(({ theme, mb }) => ({
  position: 'relative',
  '&:not(:last-child)': {
    marginBottom: mb ? `${mb}` : theme.spacing(2),
  },
}))

const GridContainerField = ({ children }) => {
  return <GridContainer columnSpacing={2}>{children}</GridContainer>
}

export { ContainerStyle, GroupStyle, GridContainerField }
