import numeral from 'numeral'

export function numberRange(field) {
  if (field?.min > 0 && field?.max) {
    return `${numeral(field?.min).format('0,0')} - ${numeral(field?.max).format('0,0')}`
  }
  if (field?.min > 0) {
    return `> ${numeral(field?.min).format('0,0')}`
  }
  if (field?.max > 0) {
    return `< ${numeral(field?.max).format('0,0')}`
  }
  return 'Not set'
}
