import { useSelector, useDispatch } from 'react-redux'

import {
  HANDLE_LOADING,
  SET_TYPE,
  SET_SEARCH_DEALS,
  SET_DEAL_DETAIL,
  SET_PAGINATE,
  SET_FILTER_SEARCH,
} from 'store/FilterSlice'
import axiosInstance from 'utils/axios'
import { GET_API, POST_API } from 'utils/api'
import { useAlert } from 'hooks'

const useFilter = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()

  const { type, isLoading, deals, dealsLength, dealDetail, paginate, isFilteredSearch } =
    useSelector(state => state.filter)

  const handleGetDeals = async (page = 1, currType = type) => {
    try {
      let API_URL = GET_API().getSellerDeals
      if (currType === 'buyerNeeds') {
        API_URL = GET_API().getBuyersNeeds
      }

      dispatch(HANDLE_LOADING(true))
      const response = await axiosInstance.get(`${API_URL}?page=${page}`)

      if (response.data.status === 'success') {
        dispatch(SET_SEARCH_DEALS(response.data))
        dispatch(HANDLE_LOADING(false))
        // console.log(response.data.deals)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const getFilteredDeals = async (page = 1, body) => {
    try {
      let API_URL = POST_API().searchSellerDeal
      if (type === 'buyerNeeds') {
        API_URL = POST_API().searchBuyerDeal
      }

      dispatch(HANDLE_LOADING(true))
      const response = await axiosInstance.post(`${API_URL}?page=${page}`, body)
      console.log(response)
      if (response.data.status === 'success') {
        dispatch(SET_SEARCH_DEALS(response.data))
        dispatch(HANDLE_LOADING(false))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeType = type => {
    dispatch(SET_TYPE(type))
    handleGetDeals(1, type)
  }

  const handlePaginate = paginate => {
    console.log(paginate)
    dispatch(SET_PAGINATE(paginate))
  }

  const enableFilterSearch = enable => {
    dispatch(SET_FILTER_SEARCH(enable))
  }

  return {
    deals,
    handleChangeType,
    handleGetDeals,
    getFilteredDeals,
    handlePaginate,
    isLoading,
    dealsLength,
    paginate,
    isFilteredSearch,
    type,
    enableFilterSearch,
  }
}

export default useFilter
