import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  deals: [],
  paginate: {
    offset: 0,
    perPage: 5,
    currentPage: 0,
    length: 0,
  },
}

const slice = createSlice({
  name: 'dealRegister',
  initialState,
  reducers: {
    HANDLE_LOADING: (state, action) => {
      state.isLoading = action.payload
    },
    SET_DEALS: (state, action) => {
      state.deals = action.payload
    },
    SET_PAGINATE: (state, action) => {
      state.paginate = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { HANDLE_LOADING, SET_DEALS, SET_PAGINATE } = actions

export default reducer
