import { useState, useEffect } from 'react'
import ShowMoreText from 'react-show-more-text'
import { Box, Stack, Chip, Tooltip, Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/styles'
import { Link } from 'react-router-dom'
import { useFilter, useApplication } from '../../../hooks'
import TargetIcon from 'assets/images/dashboard/search/target.png'
import ServiceIcon from 'assets/images/dashboard/search/service.png'

import { fToNow } from 'utils/formatTime'
import Pagination from '../../pagination/Pagination'
import {
  Label12,
  Label14,
  Label18,
  TitleStyle,
  BagdeStyle,
  Group,
  BorderStyle,
  RowStyle,
  ColStyle,
  ColorStyle,
  ImageStyle,
  BoxStyle,
  lineClampStyle,
} from '../styles'
import NoFoundResult from 'components/NoFoundResult'
import 'assets/css/ShowMoreText.css'

import NoImage from 'components/NoImage'

import RewardIcon from 'assets/icons/Rewards/Reward 19 - 24px.svg'
import CustomersIcon from 'assets/icons/Users/User 8 - 24px.svg'
import Business2Icon from 'assets/icons/Business/Business 2 - 24px.svg'
import User4Icon from 'assets/icons/Users/User 4 - 24px.svg'
import Icon39Icon from 'assets/icons/Payments/Icon 39 - 24px.svg'
import Business11Icon from 'assets/icons/Business/Business 11 - 24px.svg'
import DealDemandItem from '../dealRegister/Forms/DealDemandItem'
import SellerDealItem from '../SellerDealItem'

const Container = styled(BoxStyle)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: '0.5px solid rgb(215, 215, 228)',
  borderRadius: theme.spacing(1.25),
  // maxHeight: 270,
}))

const Label18Style = styled(Label18)(({ theme }) => ({
  cursor: 'context-menu',
  transition: 'all 200ms ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const GroupCompany = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginLeft: theme.spacing(2),
  borderLeft: '1px solid #D7D7E4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

function numberWithCommas(x) {
  console.log(x)
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const ProductOptions = ({ src, alt, label, children }) => {
  return (
    <Group
      row="true"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 0.5,
        width: '100%',
      }}
    >
      <Group row="true" align="center" sx={{ minWidth: 200 }}>
        <ImageStyle src={src} alt={alt} width={'18px'} height={'18px'} />
        <Label12 sx={{ ml: 1, ...lineClampStyle(1) }}>
          {!Number.isNaN(Number(label)) ? numberWithCommas(label) : label}
        </Label12>
      </Group>
      {Array.isArray(children) ? (
        <Label12
          sx={{
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              minWidth: 100,
              borderBottom: '0.5px solid #212244',
              position: 'absolute',
              right: 0,
              bottom: 2,
            },
          }}
        >
          {children.join(', ')}
        </Label12>
      ) : (
        ''
      )}
    </Group>
  )
}

const CompanyProfileItem = ({ src, sx, label }) => {
  return <ProductOptions src={src} sx={sx} alt="123" label={label} />
}

const CompanyProfile = ({ item }) => {
  return (
    <Box>
      <GroupCompany>
        <Label14 sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>Company’s profile</Label14>
        <CompanyProfileItem src={Business2Icon} label={item?.typeOfEnterprise} />
        <CompanyProfileItem src={User4Icon} label={`${item?.numEmployee} employees`} />
        <CompanyProfileItem src={Icon39Icon} label={`${item?.charterCaptial}`} />
        <CompanyProfileItem src={Business11Icon} label={item?.land} />
      </GroupCompany>
    </Box>
  )
}

export default function Result({ dealList, isBuyer, handleSetDealDetail }) {
  const { dealsLength, paginate, handlePaginate } = useFilter()
  const { application } = useApplication()

  const handleChangePage = e => {
    const selectedPage = e.selected
    const offset = selectedPage * paginate.perPage
    const newPaginate = { ...paginate, currentPage: selectedPage, offset }
    handlePaginate(newPaginate)
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TitleStyle>
          <ColorStyle> {dealsLength} </ColorStyle>results
        </TitleStyle>
        {isBuyer === 'seller' && application?.status !== 'Approved' && (
          <Link style={{ color: '#ca3636', fontWeight: 600, fontSize: 14, marginLeft: 10 }} to="/dashboard/profile">
            Apply for company member authorization to search for more results!
          </Link>
        )}
      </div>
      {!dealList.length ? (
        <NoFoundResult />
      ) : (
        <>
          <Box sx={{ mt: 0.75, minHeight: 860 }} data-tut="reactour__results">
            {dealList.length > 0 &&
              dealList.map(item =>
                isBuyer === 'buyer' ? (
                  <DealDemandItem
                    titles={item.title}
                    targetDetail={item.targetDetail}
                    industry={item.industry}
                    targetLocations={item.targetBusinessLocation}
                    targetDealSizes={item.targetDealSize}
                    targetRevenues={item.targetRevenue}
                    targetEBITDAs={item.targetEBITDA}
                    createdAt={item.createdAt}
                    viewOnly
                  />
                ) : (
                  <SellerDealItem item={item} handleSetDealDetail={handleSetDealDetail} />
                )
              )}
          </Box>

          <Pagination length={paginate.length} forcePage={paginate.currentPage} handleChangePage={handleChangePage} />
        </>
      )}
    </div>
  )
}
