import { useSelector, useDispatch } from 'react-redux'

import { HANDLE_LOADING, SET_USER, IS_AUTHENTICATED, HANDLE_LOGOUT } from 'store/AuthSlice'
import axiosInstance from 'utils/axios'
import { GET_API, POST_API } from 'utils/api'
import { setSession, getIdByToken } from 'utils/jwt'
import { useAlert } from 'hooks'
import { useNavigate } from 'react-router-dom'

const useAuth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useAlert()

  const { isAuthenticated, user, isLoading } = useSelector(state => state.auth)

  const handleAuthenticated = async (token = '') => {
    dispatch(HANDLE_LOADING(true))
    try {
      const accessToken = token || localStorage.getItem('accessToken')
      const getId = getIdByToken(accessToken)

      if (getId) {
        setSession(accessToken)
        const response = await axiosInstance.get(GET_API(getId).userById)

        dispatch(HANDLE_LOADING(false))

        if (response.data.status === 'success') {
          return dispatch(SET_USER(response.data.user))
        }
        return false
      }

      dispatch(HANDLE_LOGOUT())
      dispatch(HANDLE_LOADING(false))
    } catch (err) {
      console.log('error', err)
      dispatch(HANDLE_LOGOUT())
    }
  }

  const handleLogin = async form => {
    try {
      const response = await axiosInstance.post(POST_API().login, form)

      if (response.data.status === 'success') {
        handleAuthenticated(response.data.data)
        enqueueSnackbar('Login success', { variant: 'success' })
        return response
      }

      enqueueSnackbar(response.data.message, { variant: 'error' })
      return response
    } catch (error) {
      console.log('err', error)
      return false
    }
  }

  const handleRegister = async form => {
    try {
      const response = await axiosInstance.post(POST_API().register, form)

      if (response.data.status === 'success') {
        enqueueSnackbar(response.data.message, { variant: 'success' })
        return true
      }

      enqueueSnackbar(response.data.message, { variant: 'error' })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleLogout = () => {
    setSession('')
    dispatch(HANDLE_LOGOUT())
    window.localStorage.clear()
    navigate('/auth/login')
  }

  const handleForgotPassword = async ({ email }) => {
    try {
      const response = await axiosInstance.post(POST_API().forgotPassword, { email })

      if (response.data.status === 'success') {
        enqueueSnackbar(response.data.message, { variant: 'success' })
        return true
      }

      enqueueSnackbar(response.data.message, { variant: 'error' })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCheckConfirmCode = async ({ email, code }) => {
    try {
      const response = await axiosInstance.post(POST_API().codeCheck, { email, code })

      if (response.data.status === 'success') {
        return true
      }

      enqueueSnackbar('You code is invalid. Please try again', { variant: 'error' })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleResetPassword = async ({ form }) => {
    try {
      const response = await axiosInstance.post(POST_API().resetPassword, form)

      if (response.data.status === 'success') {
        enqueueSnackbar(response.data.message, { variant: 'success' })
        return true
      }

      enqueueSnackbar(response.data.message, { variant: 'error' })

      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  return {
    isAuthenticated,
    user,
    isLoading,
    handleAuthenticated,
    handleLogin,
    handleLogout,
    handleForgotPassword,
    handleRegister,
    handleCheckConfirmCode,
    handleResetPassword,
  }
}

export default useAuth
