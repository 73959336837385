import ImageDefault from 'assets/images/dashboard/ImageDefault.png'
import { Label12, ImageStyle, Group } from './_dashboard/styles'

export default () => {
  return (
    <Group
      sx={{
        background: '#F6F6F6',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <ImageStyle src={ImageDefault} alt="no" />
      <Label12 sx={{ position: 'absolute', bottom: '10%', fontWeight: 600, color: '#C6C6C6!important' }}>
        No Image
      </Label12>
    </Group>
  )
}
