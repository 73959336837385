const GET_API = (id,  page  =  1, type) => {
  return {
    // User
    userById: `/user/${id}`,
    userDeals: `/user/deals?type=${type}&page=${page}`,

    // Buyer Deal
    getBuyersNeeds: '/favoriteDeals',
    getBuyerDealById: `/favoriteDeal/${id}`,

    // Seller Deal
    getSellerDeals: '/sellerDeals',
    getSellerDealById: `/sellerDeal/${id}`,

    // Company Application
    getCompanyApplication: '/companyApplication',
    getNotifications: `/user/notifications?page=${page}`,
  }
}

const POST_API = id => {
  return {
    // Auth
    login: `/login`,
    register: '/register',
    forgotPassword: '/forgotpassword',
    codeCheck: '/confirmCode',
    resetPassword: '/resetPassword',

    // Buyer Deal
    createBuyerDeal: '/favoriteDeal',
    searchBuyerDeal: '/favoriteDeals',
    offerSellerDeal: `/favoriteDeal/${id}/offers`,

    // Seller Deal
    createSellerDeal: '/sellerDeal',
    searchSellerDeal: '/sellerDeals',
    offerBuyerDeal: `/sellerDeal/${id}/offers`,

    // Company Application
    createCompanyApplication: '/companyApplication',

    // Support
    createSupport: '/support',
  }
}

const UPDATE_API = id => {
  return {
    // Buyer Deal
    updateBuyerDeal: `/favoriteDeal/${id}`,

    // Seller Deal
    updateSellerDeal: `/sellerDeal/${id}`,

    // Company Application
    updateCompanyApplication: `/companyApplication/${id}`,

    // Notification Update
    updateStatusNotification: `/user/notifications`
  }
}

const DELETE_API = () => {
  return {
    // Buyer Deal
    deleteBuyerDeal: `/favoriteDeal`,

    // Seller Deal
    deleteSellerDeal: `/sellerDeal`,
  }
}

export { GET_API, POST_API, UPDATE_API, DELETE_API }
