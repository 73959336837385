import React from 'react'
import { styled } from '@mui/styles'
import NoSearchResult from 'assets/images/dashboard/search/NoSearchResult.png'

import { Typography } from '@material-ui/core'

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '0 auto',
}))

export default function NoFoundResult({
  title = 'No results found',
  text = `Try adjusting your search to find what you are looking for`,
  src,
}) {
  return (
    <RootStyle>
      <img src={!src ? NoSearchResult : src} alt="noFound" />
      <Typography sx={{ fontSize: 24, fontWeight: 700 }}>{title}</Typography>
      <Typography sx={{ fontWeight: 18, color: '#4A4A4A0', textAlign: 'center' }}>{text}</Typography>
    </RootStyle>
  )
}
