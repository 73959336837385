import React from 'react'
// context
import { ChatProvider } from 'contexts/ChatContext'
import { styled } from '@mui/styles'
import { Paper, Box } from '@mui/material'
import { SidebarConfig } from 'components/_dashboard'
import DashboardSidebar from './DashboardSidebar'
import DashboardNavbar from './DashboardNavbar'
import axiosInstance from 'utils/axios'
import { useTour } from '@reactour/tour'
import DialogTour from 'components/DialogTour'

import { useAuth, useApplication, useSocket } from 'hooks'
import { PATH_DASHBOARD } from 'routes/paths'
import { useNavigate, Outlet } from 'react-router-dom'

const SIDEBAR_WIDTH = 250
const SIDEBAR_WIDTH_ICON = 50

const RootStyle = styled(Box)(() => ({
  width: '100%',
  minHeight: '100vh',
}))

const MainStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  float: 'right',
  transition: 'all 300ms ease-in',
  minHeight: '100vh',
  padding: theme.spacing(0.2, 1.5),
  background: '#F8F8F8',
}))

export default function index() {
  const { setIsOpen } = useTour()

  const { isAuthenticated, handleAuthenticated } = useAuth()
  const { handleGetUserApplication } = useApplication()

  const [hideSidebar, setHideSidebar] = React.useState(true)
  const { initiateSocket, disconnectSocket, subscribeToNotifications } = useSocket()
  const [notifications, setNotifications] = React.useState([])
  const [openDialogTour, setOpenDialogTour] = React.useState(false)

  const navigate = useNavigate()

  React.useEffect(() => {
    // setOpenDialogTour(true)
    initiateSocket()
    handleGetUserApplication()
    subscribeToNotifications((err, data) => {
      if (err) return
      setNotifications(oldNotification => [...oldNotification, data])
    })
    return () => {
      disconnectSocket()
    }
  }, [])

  React.useEffect(() => {
    const getNotification = async () => {
      try {
        const response = await axiosInstance.get(`/user/notifications`)
      } catch (error) {
        console.log('error', error)
      }
    }
    getNotification()
  }, [])

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(PATH_DASHBOARD.root)
      return
    }

    handleAuthenticated()
  }, [isAuthenticated])

  const handleHideSidebar = () => {
    setHideSidebar(!hideSidebar)
  }

  return (
    <RootStyle>
      <DialogTour
        open={openDialogTour}
        handleClose={() => setOpenDialogTour(false)}
        title="Welcome to M&A "
        openTour={() => {
          setIsOpen(true)
          setOpenDialogTour(false)
        }}
      >
        Bạn có muốn mở hướng dẫn sử dụng trang web không?
      </DialogTour>

      <DashboardSidebar
        width={hideSidebar ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH}
        hideSidebar={hideSidebar}
        lists={SidebarConfig}
      />
      <MainStyle
        sx={{
          width: hideSidebar ? 'calc(100% - 50px)' : `calc(100% - ${SIDEBAR_WIDTH}px)`,
        }}
      >
        <DashboardNavbar
          handleHideSidebar={handleHideSidebar}
          isHide={hideSidebar}
          SidebarConfig={SidebarConfig}
        />
        <ChatProvider>
          <Paper
            sx={{
              height: '100%',
              minHeight: 'calc(100vh - 5vh)',
              p: 3,
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Outlet />
          </Paper>
        </ChatProvider>
      </MainStyle>
    </RootStyle>
  )
}
