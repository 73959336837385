import React, { useState, useEffect } from 'react'
import ShowMoreText from 'react-show-more-text'
import { Box, Stack, Chip, Tooltip } from '@mui/material'
import { styled } from '@mui/styles'
import { fToNow } from 'utils/formatTime'
import { Label12, Label14, Label18, Group, RowStyle, ImageStyle, BoxStyle, lineClampStyle } from './styles'
import 'assets/css/ShowMoreText.css'

import NoImage from 'components/NoImage'

import RewardIcon from 'assets/icons/Rewards/Reward 19 - 24px.svg'
import CustomersIcon from 'assets/icons/Users/User 8 - 24px.svg'
import Business2Icon from 'assets/icons/Business/Business 2 - 24px.svg'
import User4Icon from 'assets/icons/Users/User 4 - 24px.svg'
import Icon39Icon from 'assets/icons/Payments/Icon 39 - 24px.svg'
import Business11Icon from 'assets/icons/Business/Business 11 - 24px.svg'
import IndustryChip from './IndustryChip'

const Container = styled(BoxStyle)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: '0.5px solid rgb(215, 215, 228)',
  borderRadius: theme.spacing(1.25),
  // maxHeight: 270,
}))

const Label18Style = styled(Label18)(({ theme }) => ({
  cursor: 'context-menu',
  transition: 'all 200ms ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const GroupCompany = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  marginLeft: theme.spacing(2),
  borderLeft: '1px solid #D7D7E4',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

function numberWithCommas(x) {
  console.log(x)
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const ProductOptions = ({ src, alt, label, children }) => {
  return (
    <Group
      row="true"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 0.5,
        width: '100%',
      }}
    >
      <Group row="true" align="center" sx={{ minWidth: 200 }}>
        <ImageStyle src={src} alt={alt} width={'18px'} height={'18px'} />
        <Label12 sx={{ ml: 1, ...lineClampStyle(1) }}>
          {!Number.isNaN(Number(label)) ? numberWithCommas(label) : label}
        </Label12>
      </Group>
      {Array.isArray(children) ? (
        <Label12
          sx={{
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              minWidth: 100,
              borderBottom: '0.5px solid #212244',
              position: 'absolute',
              right: 0,
              bottom: 2,
            },
          }}
        >
          {children.join(', ')}
        </Label12>
      ) : (
        ''
      )}
    </Group>
  )
}

const CompanyProfileItem = ({ src, sx, label }) => {
  return <ProductOptions src={src} sx={sx} alt="123" label={label} />
}

const CompanyProfile = ({ item }) => {
  return (
    <Box>
      <GroupCompany>
        <Label14 sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>Company’s profile</Label14>
        <CompanyProfileItem src={Business2Icon} label={item?.typeOfEnterprise ?? 'Not set'} />
        <CompanyProfileItem
          src={User4Icon}
          label={`${item?.numEmployee ? `${item.numEmployee} employees` : 'Not set'}`}
        />
        <CompanyProfileItem src={Icon39Icon} label={item?.charterCaptial ?? 'Not set'} />
        <CompanyProfileItem src={Business11Icon} label={item?.land ?? 'Not set'} />
      </GroupCompany>
    </Box>
  )
}

function SellerDealItem(props) {
  const { item, handleSetDealDetail } = props
  return (
    <Container>
      <Group>
        <RowStyle sx={{ mb: 1 }}>
          <Group row="true" justify="space-between" align="center" sx={{ width: '100%' }}>
            <Label18Style sx={{ ...lineClampStyle(1) }} onClick={() => handleSetDealDetail(item)}>
              {item?.nameDeal}
            </Label18Style>
            <Label12 weight="700">Created at: {fToNow(item?.createdAt)}</Label12>
          </Group>
        </RowStyle>
        <IndustryChip industries={item.industry} isBuyer={false} />
        <RowStyle>
          <Stack sx={{ flexGrow: 1 }}>
            <Group row="true" sx={{ width: '100%' }}>
              <Box sx={{ height: 120, width: 0 }}>
                {/* {item?.images ? ( */}
                {/*  <ImageStyle src={item?.images[0]} alt="Image" width="100%" height="120px" radius="9.5px" /> */}
                {/* ) : ( */}
                {/*  <NoImage /> */}
                {/* )} */}
              </Box>
              <Group
                sx={{
                  marginLeft: 0,
                  width: '100%',
                  flexWrap: 'wrap',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Group sx={{ width: '100%' }}>
                  <Label14 sx={{ mb: 0.5, fontWeight: 600 }}>Product/Service Overview</Label14>
                  <ShowMoreText
                    /* Default options */
                    lines={2}
                    more="Show Detail"
                    less="Show less"
                    className="ShowMore resultPage"
                    anchorClass="BtnShowMore"
                    expanded={false}
                    truncatedEndingComponent="..."
                  >
                    <Label12>{item?.companyOverview ?? 'Not set'}</Label12>
                  </ShowMoreText>
                </Group>
                <Group>
                  <ProductOptions src={RewardIcon} alt="Reward Icon" color="#212244" label="Product/Service Advantage:">
                    {item?.productAdvantages?.length > 0 ? item?.productAdvantages : 'Not set'}
                  </ProductOptions>
                  <ProductOptions
                    src={CustomersIcon}
                    alt="Customer Icon"
                    color="#212244"
                    label="Ideal/Target Customer:"
                  >
                    {item?.targetCustomer?.length > 0 ? item?.targetCustomer : 'Not set'}
                  </ProductOptions>
                </Group>
              </Group>
            </Group>
          </Stack>
          <CompanyProfile item={item} />
        </RowStyle>
      </Group>
    </Container>
  )
}

export default SellerDealItem
