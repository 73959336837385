import React from 'react'

export default [
  {
    selector: '[data-tut="reactour__search"]',
    content: `search.`,
  },
  {
    selector: '[data-tut="reactour__filter"]',
    content: `Filter`,
  },
  {
    selector: '[data-tut="reactour__results"]',
    content: `Results.`,
  },
]
