import React from 'react'
import { fDate } from 'utils/formatTime'
import ShowMoreText from 'react-show-more-text'
import { useApplication } from '../../../hooks'
import axiosInstance from 'utils/axios'
import { styled } from '@mui/styles'
import { useSnackbar } from 'notistack'
import Modal from '../../modal/Modal'

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  Stack,
  IconButton,
  Chip,
  Button,
  Tooltip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as FAQIcon } from 'assets/images/dashboard/icons/faq.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/images/dashboard/icons/arrowDown.svg'
import { ReactComponent as ListComplistIcon } from 'assets/images/dashboard/icons/listsComplete.svg'

import NoImage from 'components/NoImage'

import { MButton } from 'components/_material-extend'
import { Label18, Label14, Label12, Group, GridContainer, GridItem, Dashsed, lineClampStyle, ColStyle } from '../styles'
import 'assets/css/ShowMoreText.css'
import DialogUpgrade from './DialogUpgrade'

const Label18Style = styled(Label18)(({ theme }) => ({
  cursor: 'pointer',
}))
const BoxStyle = styled(Group)(({ theme }) => ({
  borderBottom: '1px solid #EEEEEE',
  padding: theme.spacing(3, 0),
}))
const BoxDashsed = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: '1px solid #EEEEEE',
}))
const GridStyle = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 180px',
  gap: theme.spacing(3),
  alignItems: 'end',
}))
const BoxOpacity = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 72,
  left: 0,
  top: -70,
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) -45.39%, rgba(255, 255, 255, 0.83) 18.97%, #FFFFFF 100%)',
  filter: 'drop-shadow(0px 6px 16px rgba(153, 155, 168, 0.1))',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ProductOptions = ({ icon, label, children, sx, active = true, ...props }) => {
  return (
    <Group row="true" sx={{ mb: 1, ...sx }} align="center">
      <IconButton sx={{ p: 0 }}>{icon}</IconButton>
      <Group row="true" justify="space-between" align="center" sx={{ width: '100%' }}>
        <Label12
          sx={{ ml: 1, whiteSpace: 'nowrap' }}
          color={active ? '#5F5F5F' : '#212244'}
          weight={active ? 700 : 400}
        >
          {label}
        </Label12>
        {children}
      </Group>
    </Group>
  )
}

const ListItems = ({ lists }) => {
  return (
    <Box sx={{ ml: 3, my: 1 }}>
      <ul style={{ fontSize: 12, color: '#000' }}>
        {lists?.map(list => (
          <li key={list}>{list}</li>
        ))}
      </ul>
    </Box>
  )
}

const ContentRight = ({ icon, label, children, sx, active = true, ...props }) => {
  return (
    <Group row="true" sx={{ mb: 1, ...sx }} align="flex-start">
      <IconButton sx={{ p: 0 }}>{icon}</IconButton>
      <Group sx={{ width: '100%' }}>
        <Label12 sx={{ ml: 1 }} color="#212244" weight="700">
          {label}
        </Label12>
        <Group sx={{ ml: 0.5 }}>{children}</Group>
      </Group>
    </Group>
  )
}

const IndustryMenu = ({ lists }) => {
  return lists.map((list, ind) => (
    <Box key={list}>
      {ind + 1}: {list}
    </Box>
  ))
}

export default function ResultDetail({ open, handleClose, result }) {
  const { application } = useApplication()
  const [isUpgrade, setIsUpgrade] = React.useState(false)
  const [openUpgrade, setOpenUpgrade] = React.useState(false)
  const [lessMoreIndustry, setLessMoreIndustry] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar()

  const handleCloseUpgrade = () => {
    setOpenUpgrade(false)
  }
  const handleUpgrade = () => {
    setOpenUpgrade(false)
    setIsUpgrade(true)
  }

  const handleMakeOffer = async () => {
    try {
      const response = await axiosInstance.post(`/deal/${result?._id}/offer`)
      if (response.data.status === 'success') {
        enqueueSnackbar('Make deal success', { variant: 'success' })
        handleClose()
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  return (
    <Modal open={open} onClose={handleClose} showOverflow={application?.status === 'Approved'}>
      <Group>
        <Group row="true" justify="space-between" align="flex-start" sx={{ mb: 0.5 }}>
          <Label18Style>{result?.nameDeal}</Label18Style>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Group>
        <Label14>Created {result?.createdAt && fDate(result?.createdAt)}</Label14>
      </Group>

      {/* profile */}
      <BoxStyle>
        <Label18>Profile</Label18>
        <GridStyle>
          <Box>
            <Group>
              <ProductOptions icon={<ListComplistIcon />} label="Main Business Lines" sx={{ mb: 0.5 }} />
              <Box sx={{ ml: 2, mb: 2 }}>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: 'wrap',
                    gap: 0.5,
                    mb: 1,
                    height: lessMoreIndustry && 32,
                    overflow: lessMoreIndustry && 'hidden',
                  }}
                >
                  {result?.industry?.length
                    ? result?.industry.map(item => (
                        <Tooltip key={item?._id} title={<IndustryMenu lists={item?.children} />}>
                          <Chip label={`${item?.name} (${item?.children?.length})`} />
                        </Tooltip>
                      ))
                    : ''}
                </Stack>
                <Button
                  sx={{
                    '&:hover': { background: 'transparent' },
                    '& svg': {
                      transform: !lessMoreIndustry && 'rotate(180deg)',
                    },
                  }}
                  endIcon={<ArrowDownIcon />}
                  onClick={() => setLessMoreIndustry(!lessMoreIndustry)}
                >
                  See detail
                </Button>
              </Box>
            </Group>

            <Group>
              <ProductOptions icon={<ListComplistIcon />} label="Type of enterprise:" active={false}>
                <Label12>THIS IS VALUE : {result?.typeOfEnterprise}</Label12>
              </ProductOptions>
              <ProductOptions icon={<ListComplistIcon />} label="Employee:" active={false}>
                <Label12>{result?.numEmployee}</Label12>
              </ProductOptions>

              <ProductOptions icon={<ListComplistIcon />} label="Charter capital:" active={false}>
                <Label12>${result?.charterCaptial}</Label12>
              </ProductOptions>
              <ProductOptions icon={<ListComplistIcon />} label="Land, Buiding" active={false}>
                <ShowMoreText
                  /* Default options */
                  lines={1}
                  more="Show Detail"
                  less="Show less"
                  className="ShowMore"
                  anchorClass="BtnShowMore"
                  expanded={false}
                  width={212}
                  truncatedEndingComponent="..."
                >
                  <Label12>{result?.land}</Label12>
                </ShowMoreText>
              </ProductOptions>
            </Group>
          </Box>

          <NoImage />
        </GridStyle>
      </BoxStyle>

      {/* Request Details */}
      <BoxStyle>
        <Group>
          <Label18>Request Details</Label18>
          <Label14 color="#000">{result?.productDetail}</Label14>
        </Group>

        <Group>
          <Group sx={{ mt: 3 }}>
            <ProductOptions icon={<ListComplistIcon />} label="Main Business Lines" sx={{ mb: 0.5 }} />
            <Group sx={{ ml: 2 }}>
              <Label12 color="#000" sx={{ ml: 0.75 }}>
                {result?.businessLocation}
              </Label12>
            </Group>
          </Group>
          <Group sx={{ mt: 3 }}>
            <ProductOptions icon={<ListComplistIcon />} label="Idea/Target Customer:" sx={{ mb: 0.5 }} />
            <Group sx={{ ml: 2 }}>
              <Label12 color="#000" sx={{ ml: 0.75 }}>
                {result?.targetCustomer}
              </Label12>
            </Group>
          </Group>
          <Group sx={{ mt: 3 }}>
            <ProductOptions icon={<ListComplistIcon />} label="Market Segment:" sx={{ mb: 0.75 }} />
            <Group sx={{ ml: 2 }}>
              <Label12 color="#000" sx={{ ml: 0.75 }}>
                {result?.marketSegment}
              </Label12>
            </Group>
          </Group>
        </Group>
      </BoxStyle>

      {/* Offer */}
      <BoxStyle>
        <Group sx={{ mb: 3 }}>
          <Label18>Offer</Label18>
        </Group>

        <GridContainer>
          <GridItem>
            <ProductOptions icon={<ListComplistIcon />} label="Price Range:" sx={{ mb: 0.5 }} />
            <Group sx={{ ml: 2 }}>
              <Label12 color="#000" sx={{ ml: 0.75 }}>
                {/* {result?.numShareOffer} */} NONE
              </Label12>
            </Group>
          </GridItem>
          <GridItem>
            <ProductOptions icon={<ListComplistIcon />} label="Selling Purpose:" sx={{ mb: 0.5 }} />
            <Group sx={{ ml: 2 }}>
              <Label12 color="#000" sx={{ ml: 0.75 }}>
                NONE
              </Label12>
            </Group>
          </GridItem>
        </GridContainer>
      </BoxStyle>

      {/* Company Finance */}
      <BoxStyle>
        <Group sx={{ mb: 3 }}>
          <Label18>Company Finance</Label18>
        </Group>
        <Dashsed>
          <BoxDashsed>
            <Label12 weight="700" sx={{ mb: 2.5 }}>
              Business Valuation
            </Label12>
            <GridContainer rowSpacing={2}>
              <GridItem>
                <ProductOptions icon={<ListComplistIcon />} label="Equity Value:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ NONE
                  </Label12>
                </Group>
              </GridItem>
              <GridItem>
                <ProductOptions icon={<ListComplistIcon />} label="Caculation:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ NONE
                  </Label12>
                </Group>
              </GridItem>
              <GridItem>
                <ProductOptions icon={<ListComplistIcon />} label="Share Price:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ NONE
                  </Label12>
                </Group>
              </GridItem>
              <GridItem>
                <ProductOptions icon={<ListComplistIcon />} label="Number of Shares:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ {result?.numShareOffer}
                  </Label12>
                </Group>
              </GridItem>
            </GridContainer>
          </BoxDashsed>
          <BoxDashsed>
            <Label12 weight="700" sx={{ mb: 2.5 }}>
              Financial Reports
            </Label12>
            <GridContainer rowSpacing={2}>
              <GridItem>
                <ProductOptions icon={<ListComplistIcon />} label="Profit & Loss:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ none
                  </Label12>
                </Group>
              </GridItem>
              <GridItem>
                <ProductOptions icon={<ListComplistIcon />} label="Revenue Gross:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ none
                  </Label12>
                </Group>
              </GridItem>
              <GridItem xs={12}>
                <ProductOptions icon={<ListComplistIcon />} label="Strategic Report:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    NONE! To enhance and develop our current overseas business - Make a strategical partnership with
                    superior technology and business field holders
                  </Label12>
                </Group>
              </GridItem>
              <GridItem xs={12}>
                <ProductOptions icon={<ListComplistIcon />} label="Intelligence Possession:" sx={{ mb: 0.5 }} />
                <Group sx={{ ml: 2 }}>
                  <Label12 color="#000" sx={{ ml: 0.75 }}>
                    $ none
                  </Label12>
                </Group>
              </GridItem>
            </GridContainer>
          </BoxDashsed>
        </Dashsed>
      </BoxStyle>

      {/* Company Finance */}
      <BoxStyle>
        <Group sx={{ mb: 3 }}>
          <Label18>Company Finance</Label18>
        </Group>
        <Dashsed>
          {/* Existing Shareholders */}
          <BoxDashsed>
            <Label12 weight="700" sx={{ mb: 1.5 }}>
              Existing Shareholders
            </Label12>

            <GridContainer rowSpacing={2}>
              {result?.shareholder?.length
                ? result?.shareholder.map(item => (
                    <GridItem xs={12} key={item?._id}>
                      <ContentRight icon={<ListComplistIcon />} label={item?.nameCompanyOfShareholder} sx={{ mb: 0.5 }}>
                        <ListItems lists={[item?.ownership, item?.city]} />
                      </ContentRight>
                    </GridItem>
                  ))
                : ''}
            </GridContainer>
          </BoxDashsed>

          {/* Management Board */}
          <BoxDashsed>
            <Label12 weight="700" sx={{ mb: 2.5 }}>
              Management Board
            </Label12>

            <GridContainer rowSpacing={2}>
              {result?.board?.length
                ? result?.board.map(item => (
                    <GridItem xs={12} key={item?._id}>
                      <ContentRight icon={<ListComplistIcon />} label={item?.nameBoard} sx={{ mb: 0.5 }}>
                        <Label12 color="#000">{item?.positionBoard}</Label12>
                      </ContentRight>
                    </GridItem>
                  ))
                : ''}
            </GridContainer>
          </BoxDashsed>

          {/* References */}
          <BoxDashsed>
            <Label12 weight="700" sx={{ mb: 2.5 }}>
              References
            </Label12>
            {/* Media Coverage */}
            <Group sx={{ mb: 3 }}>
              {result?.reference?.length
                ? result?.reference.map(item => (
                    <ContentRight icon={<ListComplistIcon />} label={item?.nameMedia} sx={{ mb: 0.5 }} key={item?._id}>
                      <Group sx={{ mt: 2.5 }}>
                        {item?.media_coverage?.length
                          ? item?.media_coverage.map((child, ind) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <GridContainer columnSpacing={2} sx={{ mb: 1.5 }} key={`mediacoverage-${child}`}>
                                <GridItem xs={3}>
                                  <Box
                                    sx={{
                                      background: '#F6F6F6',
                                      borderRadius: 1,
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={9}>
                                  <ColStyle>
                                    <Label12 weight="700">NONE! Article title</Label12>
                                    <a
                                      href={child}
                                      target="_blank"
                                      style={{
                                        color: '#94959B',
                                        marginTop: 1,
                                        marginBottom: 0.5,
                                      }}
                                      rel="noreferrer"
                                    >
                                      {child}
                                    </a>
                                    <Label12 sx={{ ...lineClampStyle(2), color: '#000' }}>
                                      NONE! Description link
                                    </Label12>
                                  </ColStyle>
                                </GridItem>
                              </GridContainer>
                            ))
                          : ''}
                      </Group>
                    </ContentRight>
                  ))
                : ''}
            </Group>
          </BoxDashsed>
        </Dashsed>
      </BoxStyle>
      <DialogActions
        sx={{
          background: '#F5F5FA',
          py: 2.25,
          px: 3,
          position: 'relative',
          justifyContent: 'space-between',
        }}
      >
        {!(application?.status === 'Approved') && (
          <BoxOpacity>
            <Label14
              color="#DF5757"
              weight="700"
              sx={{ mt: 3, cursor: 'pointer' }}
              onClick={() => setOpenUpgrade(true)}
            >
              See detail
              <IconButton>
                <ArrowDownIcon />
              </IconButton>
            </Label14>
          </BoxOpacity>
        )}
        <Group row="true" align="center">
          <IconButton>
            <FAQIcon />
          </IconButton>
          <Label14 weight="700">About deal offer</Label14>
        </Group>
        <MButton variant="contained" onClick={handleMakeOffer}>
          Make an Offer
        </MButton>
      </DialogActions>

      {/* upgrade */}
      <DialogUpgrade open={openUpgrade} handleClose={handleCloseUpgrade} handleUpgrade={handleUpgrade} />
    </Modal>
  )
}
