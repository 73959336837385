import React from 'react'
import { styled } from '@mui/styles'
import { Box, Tooltip, Chip, Stack } from '@mui/material'
import {
  GridContainer,
  GridItem,
  Label18,
  Label16,
  Label14,
  Label12,
  lineClampStyle,
} from '../../styles'

import { ColorStyle } from 'components/_dashboard/search/ResultStyles'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-icon.svg'
import { ReactComponent as CompanyIcon } from '../../../../assets/icons/company.svg'
import { ReactComponent as CurrencyIcon } from '../../../../assets/icons/currency.svg'
import { ReactComponent as EditIcon } from '../../../../assets/images/dashboard/DR/edit.svg'
import DeleteIcon from '../../../../assets/icons/delete.svg'
import { MButton } from 'components/_material-extend'
import { Button } from '@material-ui/core'
import IndustryChip from 'components/_dashboard/IndustryChip'
import numeral from 'numeral'
import { fToNow } from '../../../../utils/formatTime'
import { numberRange } from '../../../../utils/formatNumber'

const RootStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  display: 'flex',
  flexDirection: 'column',
  border: '1px dashed #CBCBCB',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 2),
}))

const GroupStyle = styled(Box)(({ theme, mb }) => ({
  marginBottom: theme.spacing(2),
  display: 'block',
  width: '100%',
}))

const GridContainerField = ({ children }) => {
  return <GridContainer columnSpacing={2}>{children}</GridContainer>
}

const styles = {
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}

function DealDemandItem(props) {
  const {
    active,
    index,
    handleClick,
    titles,
    targetDetail,
    industry,
    targetLocations,
    targetDealSizes,
    targetRevenues,
    targetEBITDAs,
    createdAt,
    businessLocations,
    revenues,
    isSeller,
    viewOnly,
  } = props

  return (
    <Box>
      <RootStyle style={{ border: '1px solid #ECECF2', marginTop: 8 }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box style={{ maxWidth: '80%' }}>
            <Label18 weight="700" sx={lineClampStyle(2)}>
              {titles}
            </Label18>
          </Box>
          {!viewOnly ? (
            <Box>
              <Button
                sx={{ p: 0, minWidth: 0, marginRight: 2, '&:hover': { backgroundColor: '#fff' } }}
              >
                {/* <img src={EditIcon} alt="edit" /> */}
                <EditIcon />
              </Button>
              <Button sx={{ p: 0, minWidth: 0, '&:hover': { backgroundColor: '#fff' } }}>
                <img src={DeleteIcon} alt="delete" />
                {/* <DeleteIcon /> */}
              </Button>
            </Box>
          ) : (
            <Label12 weight="700">Created at: {fToNow(createdAt)}</Label12>
          )}
        </Box>
        <IndustryChip industries={industry} isBuyer />
        <GroupStyle>
          <Label14 marginBottom={1} color="#5F5F5F" fontWeight="800">
            Target detail
          </Label14>
          <Label14>{targetDetail}</Label14>
        </GroupStyle>
        <GroupStyle mb={1.5}>
          <GridContainerField
            sx={{ flexWrap: 'nowrap' }}
            style={{ borderRight: '6 solid red !important' }}
          >
            <GridItem xs={8}>
              <Label14 color="#5F5F5F" fontWeight="800">
                Basic Needs
              </Label14>
              <GridContainerField sx={{ flexWrap: 'nowrap' }}>
                <GridItem
                  style={{ marginTop: 15, fontSize: 14 }}
                  direction="column"
                  alignItems="flex-start"
                >
                  <div style={{ ...styles.fieldContainer, ...lineClampStyle(1) }}>
                    {/* <img src={CompanyIcon} alt="company" /> */}
                    <CompanyIcon />
                    <ColorStyle style={{ fontWeight: 800, marginLeft: 10 }}>
                      {`Target Location: `}
                    </ColorStyle>
                    {targetLocations}
                    {/* <Label14 sx={lineClampStyle(1)}>{targetLocations}</Label14> */}
                  </div>
                  <br />
                  <div style={{ ...styles.fieldContainer, ...lineClampStyle(1) }}>
                    {/* <img src={CurrencyIcon} alt="currency" /> */}
                    <CurrencyIcon />
                    <ColorStyle style={{ fontWeight: 800, marginLeft: 10 }}>
                      {`Target Deal Size: `}
                    </ColorStyle>
                    {numberRange(targetDealSizes)}
                  </div>
                </GridItem>

                <GridItem style={{ marginTop: 15, fontSize: 14 }}>
                  <div style={{ ...styles.fieldContainer, ...lineClampStyle(1) }}>
                    {/* <img src={CurrencyIcon} alt="currency" /> */}
                    <CurrencyIcon />
                    <ColorStyle style={{ fontWeight: 800, marginLeft: 10 }}>
                      {`Target Revenue: `}
                    </ColorStyle>
                    {numberRange(targetRevenues)}
                  </div>
                  <br />
                  <div style={{ ...styles.fieldContainer, ...lineClampStyle(1) }}>
                    {/* <img src={CurrencyIcon} alt="currency" /> */}
                    <CurrencyIcon />
                    <ColorStyle style={{ fontWeight: 800, marginLeft: 10 }}>
                      {`Target EBITDA: `}
                    </ColorStyle>
                    {numberRange(targetEBITDAs)}
                  </div>
                </GridItem>
              </GridContainerField>
            </GridItem>
          </GridContainerField>
        </GroupStyle>

        {!viewOnly && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <MButton
              startIcon={<SearchIcon />}
              variant="contained"
              sx={{ px: 5, height: 44, mb: 1.5 }}
              style={{ fontWeight: 800, fontSize: 12 }}
              type="submit"
            >
              Matching deals
            </MButton>
          </Box>
        )}
      </RootStyle>
    </Box>
  )
}

export default DealDemandItem
