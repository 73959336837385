import { createContext, useEffect, useReducer } from 'react'
import { useAuth, useSocket } from 'hooks'
// utils
import axiosInstance from 'utils/axios'

// ----------------------------------------------------------------------

const initialState = {
  rooms: [],
  roomDeal: [],
  room: {},
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { rooms, room, roomDeal } = action.payload
    return {
      ...state,
      rooms,
      room,
      roomDeal,
    }
  },
  ROOM: (state, action) => {
    const { room, roomDeal } = action.payload
    return {
      ...state,
      room,
      roomDeal,
    }
  },
  ROOM_DEAL: (state, action) => {
    const { room } = action.payload
    return {
      ...state,
      room,
    }
  },
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const ChatContext = createContext({
  ...initialState,
  changeRoom: () => Promise.resolve(),
})

function ChatProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user } = useAuth()

  useEffect(() => {
    const initialize = async () => {
      try {
        // const response = await axiosInstance.get(`/user/${user?.id}/rooms`)
        const getRooms = await axiosInstance.get(`/roomParents`)
        console.log('rooms', getRooms)

        if (getRooms.data.status === 'success') {
          const { rooms } = getRooms.data

          const getRoomDeal = await axiosInstance.get(`/roomParents/${rooms[0]._id}`)

          const getRoom = await axiosInstance.get(`/rooms/${getRoomDeal.data.rooms[0].id}`)

          if (rooms.length) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                rooms,
                roomDeal: getRoomDeal.data.rooms,
                room: getRoom.data.rooms,
              },
            })
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                rooms: [],
                roomDeal: [],
                room: {},
              },
            })
          }
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            rooms: [],
            roomDeal: [],
            room: {},
          },
        })
      }
    }

    initialize()
  }, [])

  const changeRoom = async ind => {
    const newRoom = state.rooms[ind]

    try {
      const getRoomDeal = await axiosInstance.get(`/roomParents/${newRoom._id}`)
      console.log('changeRoom getRoomDeal', getRoomDeal)

      const getRoom = await axiosInstance.get(`/rooms/${getRoomDeal.data.rooms[0].id}`)
      console.log('changeRoom getRoom', getRoom)
      dispatch({
        type: 'ROOM',
        payload: {
          roomDeal: getRoomDeal.data.rooms,
          room: getRoom.data.rooms,
        },
      })
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleChangeRoomDeal = async roomId => {
    try {
      const getRoom = await axiosInstance.get(`/rooms/${roomId}`)
      console.log('handleChangeRoomDeal getRoom', getRoom)

      dispatch({
        type: 'ROOM_DEAL',
        payload: {
          room: getRoom.data.rooms,
        },
      })
    } catch (error) {
      console.log('err', error)
    }
  }

  const handlePingMessage = pin => {
    const newRoom = state.room
    const newPins = { ...newRoom, pingMessage: [...newRoom.pingMessage, pin] }

    dispatch({
      type: 'ROOM_DEAL',
      payload: {
        room: newPins,
      },
    })
  }

  return (
    <ChatContext.Provider
      value={{
        ...state,
        changeRoom,
        handleChangeRoomDeal,
        handlePingMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export { ChatContext, ChatProvider }
