import React from 'react'
import { styled } from '@mui/styles'
import { Link as RouterLink } from 'react-router-dom'

const ColorStyle = styled('span')(({ theme }) => ({
  color: '#000',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const LogoLink = styled(RouterLink)(({ theme }) => ({
  fontSize: 30,
  fontWeight: 700,
  textDecoration: 'none',
  color: theme.palette.primary.main,
  marginTop: 9,
}))

export default function LogoMain(props) {
  const { redirect } = props
  return (
    <LogoLink to={redirect ? redirect : '/'}>
      M&A <ColorStyle>Asia</ColorStyle>
    </LogoLink>
  )
}
