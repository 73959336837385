import { format, formatDistanceToNow } from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function fDateOriginal(date) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date) {
  const oneDay = 86400000
  if (new Date().getTime() - Math.floor(new Date(date).getTime()) > oneDay) {
    return fDate(date)
  }
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  })
}
