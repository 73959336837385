import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: 'sellerDeals',
  isLoading: false,
  deals: [],
  dealsLength: 0,
  dealDetail: {},
  paginate: {
    offset: 0,
    perPage: 5,
    currentPage: 0,
    length: 0,
  },
  isFilteredSearch: false,
}

const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    HANDLE_LOADING: (state, action) => {
      state.isLoading = action.payload
    },
    SET_TYPE: (state, action) => {
      state.type = action.payload
    },
    SET_SEARCH_DEALS: (state, action) => {
      state.deals = action.payload.deals
      if (action.payload?.totalLength) {
        state.dealsLength = action.payload.totalLength
      }
    },
    SET_DEAL_DETAIL: (state, action) => {
      state.dealDetail = action.payload
    },
    SET_PAGINATE: (state, action) => {
      state.paginate = action.payload
    },
    SET_FILTER_SEARCH: (state, action) => {
      state.isFilteredSearch = action.payload
    },
  },
})

const { reducer, actions } = FilterSlice

export const {
  HANDLE_LOADING,
  SET_TYPE,
  SET_SEARCH_DEALS,
  SET_DEAL_DETAIL,
  SET_PAGINATE,
  SET_FILTER_SEARCH,
} = actions

export default reducer
