import React from 'react'
import { styled } from '@mui/styles'
import { Avatar, Badge, Box } from '@mui/material'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#8AF1B9',
    color: '#8AF1B9',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '10%',
    right: '10%',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      border: '1px solid #fff',
      content: '""',
    },
  },
}))

export default function AvatarActive({ src, alt = 'Avatar', active, sx, ...props }) {
  if (active) {
    return (
      <Box {...props}>
        <Avatar variant="rounded" src={src} alt={alt} />
      </Box>
    )
  }

  return (
    <Box {...props}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar variant="rounded" src={src} alt={alt} sx={sx} style={{ borderRadius: 100 }} />
      </StyledBadge>
    </Box>
  )
}
