import { styled } from '@mui/styles'
import { Typography, Box } from '@mui/material'
import palette from 'theme/palette'

const Label18 = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  color: '#1C1D21',
  display: ' -webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}))

const Label12 = styled(Typography)(({ theme, color, weight }) => ({
  fontWeight: `${weight}`,
  fontSize: 12,
  color: color ? `${color}` : '#5F5F5F',
}))

const Label14 = styled(Typography)(({ theme, color, weight }) => ({
  fontWeight: `${weight}`,
  fontSize: 14,
  color: color ? `${color}` : '#5F5F5F',
}))

const TitleStyle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  color: '#000',
}))

const BagdeStyle = styled('div')(({ theme }) => ({
  background: '#F6F6F6',
  borderRadius: theme.spacing(0.5),
  marginRight: theme.spacing(0.875),
  fontSize: 12,
  color: '#000',
  padding: theme.spacing(0.625, 0.75),
  cursor: 'context-menu',
}))

const Group = styled(Box)(({ theme, row, justify }) => ({
  display: 'flex',
  flexDirection: row ? 'row' : 'column',
  justifyContent: `${justify}`,
}))

const BorderStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  borderLeft: `1px solid #D7D7E4`,
  margin: theme.spacing(0, 3),
}))
const ColorStyle = styled('span')(({ theme }) => ({
  color: palette.light.primary.main,
}))

const ImageStyle = styled('img')(({ theme }) => ({}))

const RowStyle = styled(Box)(({ theme, justify }) => ({
  display: 'flex',
  justifyContent: `${justify}`,
}))

const ColStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))
export {
  TitleStyle,
  Label18,
  Label12,
  Label14,
  BagdeStyle,
  Group,
  BorderStyle,
  ColorStyle,
  ColStyle,
  RowStyle,
  ImageStyle,
}
