import React, { useState } from 'react'
import { Box, Stack, Chip, Tooltip, Menu, MenuItem } from '@mui/material'

const IndustryMenu = ({ lists }) => {
  return lists
    ? lists?.map((list, ind) => (
        <Box key={list}>
          {ind + 1}: {list}
        </Box>
      ))
    : null
}

function IndustryChip({ industries, isBuyer }) {
  const [showAllIndustries, setShowAllIndustries] = useState(false)
  return (
    <Stack direction="row" sx={{ margin: '10px 0', gap: 1, flexWrap: 'wrap', borderRadius: 4 }}>
      {industries?.length
        ? showAllIndustries
          ? industries?.map((child, index) => (
              <Tooltip
                key={`tooltip-${isBuyer ? index : child + index}`}
                title={!isBuyer && <IndustryMenu lists={child.children} />}
              >
                <Chip
                  key={`chip-${isBuyer ? index : child + index}`}
                  label={`${isBuyer ? child : child.name} ${
                    !isBuyer ? `(${child.children?.length})` : ''
                  }`}
                />
              </Tooltip>
            ))
          : industries?.map((child, index) => {
              if (index > 2) {
                return null
              }
              return (
                <Tooltip
                  key={`tooltip-${isBuyer ? index : child + index}`}
                  title={!isBuyer ? <IndustryMenu lists={child.children} /> : ''}
                >
                  <Chip
                    key={`chip-${isBuyer ? index : child + index}`}
                    label={`${isBuyer ? child : child.name} ${
                      !isBuyer ? `(${child.children?.length})` : ''
                    }`}
                  />
                </Tooltip>
              )
            })
        : null}

      {!showAllIndustries && industries?.length > 4 ? (
        <Tooltip title="Show all industries">
          <Chip label={`+${industries?.length - 3}`} onClick={() => setShowAllIndustries(true)} />
        </Tooltip>
      ) : null}
    </Stack>
  )
}

export default IndustryChip
