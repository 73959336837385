import React from 'react'
import axiosInstance from 'utils/axios'

function useProfile() {
  const handleAuthorization = async form => {
    try {
      const response = await axiosInstance.post('/companyApplication', form)

      if (response.data.status === 'success') {
        return true
      }

      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  return {
    handleAuthorization,
  }
}

export default useProfile
