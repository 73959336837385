import { styled } from '@mui/styles'
import React from 'react'
import numeral from 'numeral'
import {
  Box,
  Typography,
  MenuItem,
  TextField,
  Select,
  FormControlLabel,
  Checkbox,
  Grid,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
  // TextareaAutosize,
} from '@mui/material'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { makeStyles } from '@material-ui/styles'

const lineClampStyle = (line = 1) => {
  return {
    display: ' -webkit-box',
    WebkitLineClamp: `${line}`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  }
}

const BoxStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: '1px solid #ECECF2',
  marginTop: theme.spacing(1),
  transition: 'all 0.5s ease',
}))

const Label18 = styled(Typography)(({ theme, clamp, color, align }) => ({
  fontWeight: 700,
  fontSize: 18,
  color: color ? `${color}` : '#1C1D21',
  textAlign: `${align}`,
}))

const Label16 = styled(Typography)(({ theme, color, align }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: color ? `${color}` : '#1C1D21',
  textAlign: `${align}`,
}))

const Label12 = styled(Typography)(({ theme, align, color, weight }) => ({
  fontWeight: `${weight}`,
  fontSize: 12,
  color: color ? `${color}` : '#5F5F5F',
  textAlign: `${align}`,
}))

const Label10 = styled(Typography)(({ theme, align, color, weight }) => ({
  fontWeight: `${weight}`,
  fontSize: 10,
  color: color ? `${color}` : '#5F5F5F',
  textAlign: `${align}`,
}))
const Label14 = styled(Typography)(({ theme, align, color, weight }) => ({
  fontWeight: `${weight}`,
  fontSize: 14,
  color: color ? `${color}` : '#5F5F5F',
  textAlign: `${align}`,
}))

const TitleStyle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  color: '#000',
}))

const BagdeStyle = styled('p')(({ theme }) => ({
  background: '#F6F6F6',
  borderRadius: theme.spacing(0.5),
  marginRight: theme.spacing(0.875),
  fontSize: 12,
  color: '#000',
  padding: theme.spacing(0.625, 0.75),
  cursor: 'context-menu',

  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  // minWidth: 0,
}))
// {Revenue (Million USD)}

const Group = styled(Box)(({ last, row, align, justify, textAlign }) => ({
  display: 'flex',
  flexDirection: row ? 'row' : 'column',
  justifyContent: `${justify}`,
  alignItems: `${align}`,
  borderBottom: !last ? 'none' : `1px solid #D7D7E4`,
  textAlign,
}))

const BorderStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  borderLeft: `1px solid #D7D7E4`,
  margin: theme.spacing(0, 3),
}))
const ColorStyle = styled('span')(({ theme }) => ({
  color: '#CA3636',
}))

const ImageStyle = styled('img')(({ theme, shadow, width, height, radius, sx, color }) => ({
  width: `${width}`,
  height: `${height}`,
  objectFit: 'cover',
  borderRadius: `${radius}`,
  boxShadow: `${shadow}`,
  resize: 'cover',
  color: `${color}`,
}))

const RowStyle = styled(Box)(({ theme, width, direction, align, justify }) => ({
  display: 'flex',
  justifyContent: `${justify}`,
  alignItems: `${align}`,
  flexDirection: `${direction}`,
  width: `${width}`,
}))

const ColStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: '#aaa',
  },
}))

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles()
  console.log('classes', classes)
  return <div className={classes.placeholder}>{children}</div>
}

const SelectStyle = styled(Select)(({ theme }) => ({
  // marginTop: theme.spacing(1),
  fontSize: 12,
  '& .MuiSelect-select': {
    padding: theme.spacing(1.25),
    backgroundColor: '#F5F5FA',
  },
}))

const SelectField = React.forwardRef((props, ref) => {
  const lineClamp = lineClampStyle(1)
  const { error, errorMessage, label, required, value, handleChange, lists, children, placeholder, ...restProps } =
    props
  return (
    <>
      <Group>
        <Label12 color="#8181A5" sx={{ mb: 1, ...lineClamp }} weight={700}>
          {label} {required ? <ColorStyle style={{ fontSize: 12, paddingLeft: '6px' }}>*Required</ColorStyle> : null}
        </Label12>
        <SelectStyle
          // ref={ref}]
          // sx={{ color: value ? '#111' : '#B5B5D1', fontSize: 12 }}
          {...restProps}
          sx={restProps.defaultValue === value && { color: '#9ca0a8' }}
          value={value}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={value => (value?.length ? (Array.isArray(value) ? value.join(', ') : value) : placeholder)}
        >
          {lists.map(list => (
            <MenuItem key={list.value} value={list.value}>
              {list.label}
            </MenuItem>
          ))}
        </SelectStyle>
        {children}
      </Group>
      {error !== null ? <ColorStyle style={{ fontWeight: 700, fontSize: 14 }}>{errorMessage}</ColorStyle> : null}
    </>
  )
})

const InputBoxField = ({ required, label, children, ...props }) => {
  const lineClamp = lineClampStyle(1)

  return (
    <Group {...props}>
      <Label14 color="rgb(33, 34, 68)" sx={{ mb: 1, ...lineClamp }}>
        {label}
        <ColorStyle style={{ fontWeight: 700, fontSize: 14 }}>{required}</ColorStyle>
      </Label14>
      {children}
    </Group>
  )
}

const TextAreaField = ({ label, error, type, required, errorMessage, sx, rows = 3, ...props }) => {
  return (
    <Box>
      <Label14 color="#8181A5" sx={{ mb: 1 }} weight={700}>
        {label} {required ? <ColorStyle style={{ fontSize: 14, paddingLeft: '6px' }}>*Required</ColorStyle> : null}
      </Label14>
      <TextFieldStyle
        onChange={e => (type === 'number' ? (e.target.value = numeral(e.target.value).format('0,0')) : e.target.value)}
        //
        style={{ backgroundColor: '#F5F5FA', border: 'none', borderRadius: '8px' }}
        variant="outlined"
        multiline
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        rows={rows}
        sx={{
          color: '#000',
          '& .MuiFilledInput-input': {
            background: 'transparent!important',
          },
          ...sx,
        }}
        {...props}
      />
      {error ? <ColorStyle style={{ fontWeight: 700, fontSize: 14 }}>{errorMessage}</ColorStyle> : null}
    </Box>
  )
}

const BoxField = ({ required, label, children, sx, ...props }) => {
  const lineClamp = lineClampStyle(1)

  return (
    <Group {...props} sx={{ height: '100%', ...sx }}>
      <Label14 color="#8181A5" sx={{ mb: 1, ...lineClamp }} fontWeight={600}>
        {label}
        <ColorStyle style={{ fontWeight: 700, fontSize: 14 }}>{required}</ColorStyle>
      </Label14>
      {children}
    </Group>
  )
}

const TextFieldStyle = styled(TextField)(({ theme, weight }) => ({
  width: '100%',
  '& .MuiFilledInput-input': {
    // borderRadius: theme.spacing(1),
    // padding: theme.spacing(1.25, 1.25, 1.5),
    fontSize: 12,
    background: '#F5F5FA',
    color: '#B5B5D1',
    borderRadius: theme.spacing(1),
    fontWeight: weight ? `${weight}` : 400,
  },
  '& .MuiOutlinedInput-input': {
    // padding: theme.spacing(1.25),
    cursor: 'text',
    fontSize: 12,
    background: '#F5F5FA',
    color: '#212244',
    borderRadius: theme.spacing(1),
    border: '0 !important',
    '&:focus-visible': {
      outLine: 'none',
    },
  },
}))

const CheckboxField = ({ label, size, sx, ...props }) => {
  return (
    <FormControlLabel
      control={<Checkbox {...props} inputProps={{ 'aria-label': 'checkbox' }} />}
      label={label}
      labelPlacement="end"
      sx={{
        '&.MuiFormControlLabel-root': {
          marginRight: 0,
        },
        '& .MuiSvgIcon-root': {
          width: 20,
        },
        '& .MuiTypography-root': {
          fontSize: size ? `${size}` : 12,
          fontWeight: 400,
          color: '#000',
        },
        ...sx,
      }}
    />
  )
}

const GridContainer = ({ children, ...props }) => {
  return (
    <Grid container sx={{ position: 'relative' }} {...props}>
      {children}
    </Grid>
  )
}

const GridItem = ({ children, xs = 6, ...props }) => {
  return (
    <Grid item xs={xs} {...props}>
      {children}
    </Grid>
  )
}
const Dashsed = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px dashed #CBCBCB',
  borderRadius: theme.spacing(1.5),
  '&:not(:last-child)': {
    marginBottom: theme.spacing(4),
  },
}))
const MOutlinedInputStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  background: '#F5F5FA',
  '& .MuiOutlinedInput-input': {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.25, 1.25, 1.5, 1.5),
    fontSize: 12,
    background: '#F5F5FA',
    '&:hover': {
      border: 'none',
    },
    '& .MuiOutlinedBase-root': {
      '&:focus': {
        border: 'none',
      },
    },
  },
}))

const MOutlinedInput = React.forwardRef((props, ref) => {
  const { label, required, type, error, errorMessage, ...restProps } = props
  return (
    <>
      {label ? (
        <Label12 color="#8181A5" sx={{ mb: 1 }} weight={700}>
          {label} {required ? <ColorStyle style={{ paddingLeft: '6px' }}>*Required</ColorStyle> : null}
        </Label12>
      ) : null}

      <MOutlinedInputStyle
        onChange={e => (type === 'number' ? (e.target.value = numeral(e.target.value).format('0,0')) : e.target.value)}
        // value={type === 'number' ? numeral(value).format('0,0') : value}
        {...restProps}
      >
        {label}
      </MOutlinedInputStyle>
      {error ? <ColorStyle style={{ fontWeight: 700, fontSize: 12 }}>{errorMessage}</ColorStyle> : null}
    </>
  )
})

const MOutlinedMinMaxInput = React.forwardRef((props, ref) => {
  const { label, required, nameMin, nameMax, handleChange, type, error, errorMessage, ...restProps } = props
  return (
    <>
      {label ? (
        <Label14 color="#8181A5" sx={{ mb: 1 }} weight={700}>
          Target Deal Size (Million USD)
        </Label14>
      ) : null}

      <GridContainer
        columnSpacing={2}
        sx={{ flexWrap: 'nowrap', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <GridItem>
          <MOutlinedInput
            style={{ backgroundColor: '#F5F5FA' }}
            startAdornment={
              <InputAdornment position="start" style={{ marginRight: 0 }}>
                $
              </InputAdornment>
            }
            type="number"
            name={nameMin}
            variant="outlined"
            onBlur={handleChange}
            placeholder="Minimum"
          />
        </GridItem>
        <ArrowForwardIcon
          sx={{
            height: '15px',
          }}
        />
        <GridItem style={{ paddingLeft: 0 }}>
          <MOutlinedInput
            style={{ backgroundColor: '#F5F5FA' }}
            startAdornment={
              <InputAdornment position="start" style={{ marginRight: 0 }}>
                $
              </InputAdornment>
            }
            type="number"
            name={nameMax}
            variant="outlined"
            onBlur={handleChange}
            placeholder="Maximum"
          />
        </GridItem>
      </GridContainer>
    </>
  )
})

// const MTextareaAutosizeStyle = styled(TextareaAutosize)(({ theme }) => ({
//   border: '1px solid #c4c4c4',
//   width: '100%',
//   fontSize: 14,
//   padding: theme.spacing(1),
//   borderRadius: theme.spacing(1),
//   fontWeight: 400,
//   '&:focus': {
//     border: '1px solid #ca3636',
//   },
//   '&:hover': {
//     border: '1px solid #111',
//   },
// }))

// const MTextareaAutosize = ({ error, errorMessage, label, ...props }) => {
//   return (
//     <>
//       <Label14 color="#000" sx={{ mb: 1 }}>
//         {label}
//         <ColorStyle style={{ fontWeight: 700, fontSize: 12, paddingLeft: '12px' }}>
//           {error ? `*${errorMessage}` : ''}
//         </ColorStyle>
//       </Label14>
//       <MTextareaAutosizeStyle {...props} />
//     </>
//   )
// }
export {
  // MTextareaAutosize,
  MOutlinedInput,
  MOutlinedMinMaxInput,
  Dashsed,
  Label10,
  GridContainer,
  GridItem,
  TitleStyle,
  Label18,
  Label12,
  Label14,
  Label16,
  BagdeStyle,
  Group,
  BorderStyle,
  ColorStyle,
  ColStyle,
  RowStyle,
  ImageStyle,
  BoxStyle,
  SelectField,
  InputBoxField,
  TextFieldStyle,
  CheckboxField,
  BoxField,
  lineClampStyle,
  TextAreaField,
}
