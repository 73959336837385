import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notifications: [],
  totalUnSeenNotificationLength: 0,
  totalNotificationLengths: 0,
  totalNotificationPages: 0,
  currentNotyPage: 1,
  isLoading: false,
  hasMore: false,
  seenAll: false,
  isShowToast: false,
  isClose:false,
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    SET_NOTIFICATIONS: (state, action) => {
      state.notifications = action.payload
    },
    SET_NOTIFICATIONS_LENGTH: (state, action) => {
      state.totalNotificationLengths = action.payload.totalLength
      state.totalNotificationPages = action.payload.totalPage
      state.totalUnSeenNotificationLength = action.payload.totalUnseenNotifications
      state.hasMore = action.payload
    },
    HANDLE_LOADING: (state, action) => {
      state.isLoading = action.payload
    },
    HANDLE_UPDATE_UNSEEN: (state, action) => {
      state.totalUnSeenNotificationLength += 1
    },
    HANDLE_READ_ALL_NOTIFICATIONS: (state, action) => {
      state.totalUnSeenNotificationLength = 0
      state.seenAll = action.payload
    },
    HANDLE_SET_CURRENT_PAGE: (state, action) => {
      state.currentNotyPage = action.payload
    },
    HANDLE_SHOW_TOAST_NOTIFICATION: (state, action) => {
      state.isShowToast = action.payload
    },
    HANDLE_CLOSE_TOAST : (state,action) => {
      state.isClose = action.payload
    }
  },
})

const { reducer, actions } = slice

export const {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_LENGTH,
  HANDLE_LOADING,
  HANDLE_UPDATE_UNSEEN,
  HANDLE_READ_ALL_NOTIFICATIONS,
  HANDLE_SET_CURRENT_PAGE,
  HANDLE_SET_SEEN_ALL_NOTIFICATION,
  HANDLE_SHOW_TOAST_NOTIFICATION,
  HANDLE_CLOSE_TOAST
} = actions

export default reducer
